<template>
  <div class="equip_wrap">
    <div class="filter_list">
      <!--    <el-input v-model="searchOption.name" placeholder="设备标识码" clearable-->
      <!--              class="handle-input mr10"/>-->
      <!--    <el-button type="primary" icon="el-icon-search" @click="handleSearch" class="ml20 mt10 searchBtn"/>-->
      <!--    <el-button type="primary" icon="el-icon-plus" @click="bindEquipVisible=true" class="ml20 mt10 searchBtn"/>-->
    </div>
    <el-table
        :data="tableData"
        :style="'width: 100%;overflow-y:auto;margin-top:10px'"
        :show-header="true"
    >
      <el-table-column
          type="index"
          label="序号"
          fixed
          align="left"
          width="50"
      />
      <el-table-column prop="entityNumber" label="设备编码" align="left"/>
      <el-table-column label="设备类型" align="left">
        <template slot-scope="scope">
          <span>
            {{ formateEquipType(scope.row.entityType) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="设备厂商" align="left">
        <template slot-scope="scope">
          <span>
            {{ formateEquipFactory(scope.row.entityFactory) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="left">
        <template slot-scope="scope">
          <el-button
              v-has-permission="[SYSTEM_SETTING.FENCE_SETTING.UN_BIND]"
              type="text"
              @click="deleteBind(scope.row.entityNumber,scope.row.entityFactory)"
          >
            解绑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="prev, pager, next,total"
          :total="searchOption.totalCount"
          :page-size="10"
          @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
<script>
import equipApi from '@/api/equipment'
import {Message} from 'element-ui'
import {formatFactory, equipTypeFormat} from '@/api/carview'
import {SYSTEM_SETTING} from "@/permission";

export default {
  data() {
    return {
      bindEquipVisible: false,
      searchOption: {
        name: '',
        pageSize: '10',
        totalCount: 1,
        pageNum: 1
      },
      tableData: [],
      SYSTEM_SETTING
    }
  },
  props: {
    fenceId: String
  },
  comments: {},
  mounted() {
    this.loadData()
  },
  methods: {
    handleSearch() {
      this.loadData()
    },
    loadData() {
      let param = {
        pageNum: this.searchOption.pageNum,
        pageSize: this.searchOption.pageSize,
        fenceId: this.fenceId
      }
      equipApi.list(param).then(res => {
        if (res.code == 0) {
          this.tableData = res.result.list
          this.searchOption.totalCount = res.result.total
        }
      })
    },
    deleteBind(number, facID) {
      let param = {
        fenceId: this.fenceId,
        entityNumber: number,
        entityFactory: facID
      }
      equipApi.removeBind(param).then(res => {
        if (res.code == 0) {
          Message.success({
            message: "操作成功",
          });
          this.$emit('success')
        } else {
          Message.error({
            message: res.msg,
          })
        }
      })
    },
    handleCurrentChange(val) {
      this.searchOption.pageNum = val
      this.loadData()
    },
    formateEquipFactory(v) {
      return formatFactory(v)
    },
    formateEquipType(v) {
      return equipTypeFormat(v)
    },
    indexMethod(index) {
      let curPage = this.searchOption.pageNum
      let pageSize = this.searchOption.pageSize
      return (index + 1) + (curPage - 1) * pageSize
    }
  }
}
</script>
<style scoped>
.equip_wrap {
  display: flex;
  flex-direction: column;
}

.filter_list {
  display: flex;
  flex-direction: row;
}

.searchBtn {
  height: 30px;
  background: #3b7Bcf;
}

.handle-input {
  width: 200px;
  display: inline-block;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #FFF;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
</style>
