<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond">
      <el-input
          v-model="searchOption.orderNumber"
          placeholder="订单编号"
          class="handle-input mr10 mt10"
          clearable
      />
      <el-input
          v-model="searchOption.carNumber"
          placeholder="车牌号"
          class="handle-input mr10 mt10"
          clearable
      />
      <el-input
          v-model="searchOption.carName"
          placeholder="车辆名称"
          class="handle-input mr10 mt10"
          clearable
      />
      <el-input
          v-model="searchOption.carVin"
          placeholder="车架号"
          class="handle-input mr10 mt10"
          clearable
      />
      下单时间：
      <el-date-picker
          v-model="searchOption.orderTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
      />
      <span class="ml10">取车时间：</span>
      <el-date-picker
          v-model="searchOption.realPickTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
      />
      <span class="ml10">预计还车时间：</span>
      <el-date-picker
          v-model="searchOption.planGiveTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
      >
      </el-date-picker>
      <el-input
          v-model="searchOption.orderUser"
          placeholder="用户姓名"
          class="handle-input mr10 mt10 ml10"
          clearable
      />
      <el-select
          v-model="searchOption.orderCity"
          class="handle-select mr10 mt10"
          placeholder="城市"
          clearable
      >
        <el-option
            v-for="item in cities"
            :key="item.id"
            :label="item.name"
            :value="item.name"
        >
          <span style="float: left">
            {{ item.name }}
          </span>
          <span style="float: right; color: #8492a6; font-size: 13px">
            {{ item.name }}
          </span>
        </el-option>
      </el-select>
      <el-select
          v-model="searchOption.status"
          class="handle-select mr10 mt10"
          placeholder="订单状态"
          clearable
      >
        <el-option key="1" label="确认取车" value="1"></el-option>
        <el-option key="2" label="确认还车" value="2"></el-option>
        <el-option key="3" label="订单取消" value="3"></el-option>
      </el-select>
      <el-select
          v-model="searchOption.orderType"
          class="handle-select mr10 mt10"
          placeholder="订单类型"
          clearable
          @click="warnTypeVisible = true"
      >
        <el-option key="1" label="自营" value="1"></el-option>
        <el-option key="2" label="携程" value="2"></el-option>
        <el-option key="3" label="悟空" value="3"></el-option>
        <el-option key="4" label="凹凸" value="4"></el-option>
        <el-option key="5" label="租租" value="5"></el-option>
        <el-option key="8" label="飞猪" value="8"></el-option>
        <el-option key="9" label="南航" value="9"></el-option>
        <el-option key="11" label="哈啰" value="11"></el-option>
        <el-option key="13" label="车生活" value="13"></el-option>
        <el-option key="15" label="滴滴租车" value="15"></el-option>
        <el-option key="99" label="其它" value="99"></el-option>
      </el-select>
      <el-select
          v-model="searchOption.isFocus"
          class="handle-select mr10 mt10"
          placeholder="重点关注"
          clearable
      >
        <el-option key="0" label="未关注" value="0"></el-option>
        <el-option key="1" label="重点关注" value="1"></el-option>
      </el-select>
      <el-select
          v-model="searchOption.isMortgage"
          class="handle-select mr10 mt10"
          placeholder="抵押车辆"
          clearable
      >
        <el-option key="0" label="未抵押" value="0"></el-option>
        <el-option key="1" label="抵押车辆" value="1"></el-option>
      </el-select>
      <el-select
          v-model="searchOption.isControl"
          class="handle-select mr10 mt10"
          placeholder="疫情管控"
          clearable
      >
        <el-option key="0" label="未管控" value="0"></el-option>
        <el-option key="1" label="疫情管控" value="1"></el-option>
      </el-select>
      <el-select
          v-model="searchOption.isPriorityReminder"
          class="handle-select mr10 mt10"
          placeholder="优先提醒"
          clearable
      >
        <el-option key="0" label="未提醒" value="0"></el-option>
        <el-option key="1" label="优先提醒" value="1"></el-option>
      </el-select>
      <el-select
          v-model="searchOption.isFirstLease"
          class="handle-select mr10 mt10"
          placeholder="首次出租"
          clearable
      >
        <el-option key="0" label="非首次" value="0"></el-option>
        <el-option key="1" label="首次出租" value="1"></el-option>
      </el-select>
      <el-select
          v-model="searchOption.isLockCarnumber"
          class="handle-select mr10 mt10"
          placeholder="锁定"
          clearable
      >
        <el-option key="0" label="未锁定" value="0"></el-option>
        <el-option key="1" label="锁定" value="1"></el-option>
      </el-select>
      <el-select
          v-model="searchOption.isFirstApplication"
          class="handle-select mr10 mt10"
          placeholder="首次申请"
          clearable
      >
        <el-option key="0" label="未申请" value="0"></el-option>
        <el-option key="1" label="首次申请" value="1"></el-option>
      </el-select>
      <el-button
          type="primary"
          icon="el-icon-search"
          class="ml20 mt10 searchBtn"
          @click="handleSearch()"
      >
        搜索
      </el-button>
      <el-button
          v-has-permission="[OPERATE_LIST_P.ADD]"
          type="primary"
          icon="el-icon-plus"
          class="ml20 mt10 searchBtn"
          @click="orderAdd()"
      >
        新增
      </el-button>
      <el-button
          ref="btn_hb"
          v-has-permission="[OPERATE_LIST_P.MERGE]"
          type="primary"
          icon="el-icon-document"
          class="ml20 mt10 searchBtn"
          @click="orderMerge()"
      >
        {{ labelTxt }}
      </el-button>
      <el-button
          v-has-permission="[OPERATE_LIST_P.EXPORT]"
          type="success"
          class="cell_btn iconfont icon-daochu"
          :loading="exportLoading"
          @click="ExcelEntity()"
      >
        导出订单
      </el-button>
    </div>
    <div class="data_list" style="margin-top: 10px">
      <el-table
          ref="table"
          v-loading="loading"
          element-loading-text="数据加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          highlight-current-row
          :loading="loading"
          :data="tableData"
          :height="contentHeight - 120"
          :header-cell-style="{ background: '#FFF', color: '#212121' }"
          @sort-change="tableSortChange"
      >
        <el-table-column type="index" label="序号" :index="indexMethod" align="left" width="50"/>
        <el-table-column label="订单编号/原订单编号" align="left" width="160">
          <template slot-scope="scope">
            <el-button
                type="text"
                style="user-select: unset"
                @click="orderEdit(scope.row.orderNumber)"
            >
              {{ scope.row.orderNumber }}
            </el-button>
            <span>/{{ scope.row.preNumber }}</span>
            <div>
              [{{ formatOrderType(scope.row.orderType) }}]
              <span v-if="scope.row.isMortgage == 1"> [抵押车辆] </span>
              <span v-if="scope.row.isControl == 1"> [疫情管控] </span>
              <span v-if="scope.row.isFocus == 1"> [重点关注] </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="重点监控" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.isKeyMonitoring == 0"> 否 </span>
            <span v-if="scope.row.isKeyMonitoring == 1"> 是 </span>
          </template>
        </el-table-column>
        <el-table-column label="车牌号" align="left" width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="showCarInfo(scope.row.carVo)">
              {{ scope.row.carVo && scope.row.carVo.carNumber }}
            </el-button>
            <span v-if="scope.row.isPriorityReminder == 1">
              [优]
            </span>
            <span v-if="scope.row.isLockCarnumber == 1">
              [锁]
            </span>
          </template>
        </el-table-column>
        <!--
         <el-table-column label="用户姓名" align="left" >
          <template slot-scope="scope">
            <el-button type="text" >{{scope.row.orderUser}}</el-button>
          </template>
        </el-table-column>
        -->
        <el-table-column label="车辆名称" align="left" width="160">
          <template slot-scope="scope">
            <div>
              {{ scope.row.carVo && scope.row.carVo.carName }}
              <span
                  v-if="scope.row.isFirstLease == 1"
                  style="color: red;"
              >
                [首]
              </span>
            </div>
          </template>
        </el-table-column>
        <!--
        <el-table-column prop="carVin" label="车架号" width="160" align="left" />
        -->
        <el-table-column label="客户姓名" align="left" width="120">
          <template slot-scope="scope">
            <div>
              {{ scope.row.orderUser }}
              <span v-if="scope.row.isFirstApplication == 1" style="color: red;"> [首] </span>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="orderTel" label="电话" align="left" width="140" /> -->
        <el-table-column prop="areaName" width="200" label="身份信息" align="left">
          <template slot-scope="scope">
            <span v-if="scope.row.areaName && scope.row.idCardTopSix">
              {{ scope.row.areaName }}-{{ scope.row.idCardTopSix }}
            </span>
            <span v-else>
              {{ scope.row.areaName }}{{ scope.row.idCardTopSix }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="orderCity" label="城市" align="left"/>
        <el-table-column prop="orderTime" label="下单时间" align="left" :formatter="formatTime" width="160"/>
        <el-table-column prop="realPickTime" label="取车时间" align="left" :formatter="formatTime" width="160"/>
        <el-table-column label="预计还车时间" prop="planGiveTime" align="left" sortable="custom" width="160">
          <template slot-scope="scope">
            <div class="verticalCol">
              <span>
                {{ timeToStr(scope.row.planGiveTime) }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="orderMil" label="订单里程" align="left"/>
        <el-table-column prop="status" label="订单状态" align="left" :formatter="formatOrder" show-overflow-tooltip/>
        <el-table-column label="备注" align="left" width="200">
          <template slot-scope="scope">
            <div class="verticalRow">
              <span v-if="inputFocus != scope.row.orderId">
                {{ scope.row.remark }}
              </span>
              <el-input
                  v-if="inputFocus == scope.row.orderId"
                  v-model="scope.row.remark"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 3 }"
                  @blur="updateRemark(scope.row)"
              />
              <i
                  v-has-permission="[OPERATE_LIST_P.REMARK_EDIT]"
                  class="el-icon-edit editIcon label"
                  @click="inputFocus = scope.row.orderId"
              >
                备注
              </i>
              <i
                  v-has-permission="[OPERATE_LIST_P.REMARK_DETAIL]"
                  class="el-icon-search editIcon label"
                  @click="viewAlarm(scope.row.orderNumber)"
              >
                查看
              </i>
              <i
                  v-has-permission="[OPERATE_LIST_P.REMARK_LOG]"
                  class="el-icon-search editIcon label"
                  @click="viewOrderLogList(scope.row.orderNumber)"
              >
                日志
              </i>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" fixed="right" width="160">
          <template slot-scope="scope">
            <el-row v-if="scope.row.carVo">
              <el-col :span="8">
                <el-button
                    v-has-permission="[OPERATE_LIST_P.LOCATION]"
                    type="text"
                    icon="el-icon-location-information"
                    @click="locationEquipment(scope.row.carVo && scope.row.carVo.carNumber,scope.row.orderCity)"
                >
                  位置
                </el-button>
              </el-col>
              <el-col :span="8">
                <el-button
                    v-has-permission="[OPERATE_LIST_P.TRACE]"
                    type="text"
                    icon="el-icon-aim"
                    @click="toTrace(scope.row)"
                >
                  轨迹
                </el-button>
              </el-col>
              <!--
              <el-col :span="8">
                <el-button type="text" icon="el-icon-edit" @click="orderEdit(scope.row.orderNumber)">修改</el-button>
              </el-col>
              -->
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next,total"
            :current-page.sync="searchOption.cur_page"
            :total="searchOption.totalCount"
            :page-size="searchOption.pageSize"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
        />
      </div>
    </div>

    <!--订单信息-->
    <el-dialog
        v-if="orderInfoVisible"
        :visible.sync="orderInfoVisible"
        :show-close="false"
        width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>订单信息</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderInfoVisible = false"
        />
      </div>
      <OrderInfo :orderId="selectOrerId"/>
    </el-dialog>

    <!--车辆信息-->
    <el-dialog
        v-if="carInfoVisible"
        :visible.sync="carInfoVisible"
        :show-close="false"
        width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>车辆信息</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="carInfoVisible = false"
        />
      </div>
      <CarInfo :carInfoObj="carInfo"/>
    </el-dialog>

    <!--车辆实时位置-->
    <el-dialog
        v-if="realTimePosVisible"
        :visible.sync="realTimePosVisible"
        :show-close="false"
        :fullscreen="fullscreen"
        :class="fullscreen ? 'nopadding dialogFullSc' : 'nopadding dialogLessSc'"
    >
      <div slot="title" class="dialog_header">
        <span>车辆实时位置</span>
        <div>
          <i
              class="iconfont icon-zuixiaohua_huaban1"
              style="font-size: 26px"
              @click="fullscreen = false"
          />
          <i
              class="iconfont icon-zuidahua"
              style="font-size: 26px"
              @click="fullscreen = true"
          />
          <i
              class="el-icon-close"
              style="font-size: 26px"
              @click="realTimePosVisible = false"
          />
        </div>
      </div>
      <VehiclePosition
          :orderNumber="selectOrderNumber"
          :city="selectOrderCity"
      />
    </el-dialog>

    <!--车辆轨迹-->
    <el-dialog
        :visible.sync="orderTraceVisible"
        v-if="orderTraceVisible"
        :show-close="false" class="nopadding"
        :style="'height:' + pageHeight * 0.9 + 'px'" width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>车辆轨迹</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderTraceVisible = false"
        />
      </div>
      <VehicleTrace
          :orderNumber="selectOrderNumber"
          :city="selectOrderCity"
          :startTime="selectOrderStartTime"
      />
    </el-dialog>

    <!--订单新增-->
    <el-dialog
        v-if="orderAddVisible"
        class="nopadding"
        width="60%"
        :visible.sync="orderAddVisible"
        :show-close="false"
    >
      <div slot="title" class="dialog_header">
        <span>订单新增</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderAddVisible = false"
        />
      </div>
      <OrderAdd @closeWindow="closeWindow"/>
    </el-dialog>

    <!--订单修改-->
    <el-dialog
        v-if="orderEditVisible"
        width="60%"
        :visible.sync="orderEditVisible"
        :show-close="false" class="nopadding"
    >
      <div slot="title" class="dialog_header">
        <span>订单修改</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderEditVisible = false"
        />
      </div>
      <OrderEdit
          :orderNumber="selectOrderNumber"
          :is-check="!editOrderInfoFlag"
          @closeWindow="closeWindowEdit"
      />
    </el-dialog>

    <!--订单合并 -->
    <el-dialog
        v-if="orderMergeVisible"
        :visible.sync="orderMergeVisible"
        :show-close="false" class="nopadding"
        :style="'height:' + pageHeight * 0.9 + 'px'" width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>订单合并</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderMergeVisible = false"
        />
      </div>
      <OrderMerge/>
    </el-dialog>

    <!--报警历史列表 -->
    <el-dialog
        v-if="alarmListVisible"
        width="60%"
        :visible.sync="alarmListVisible"
        :show-close="false"
    >
      <div slot="title" class="dialog_header">
        <span>订单备注列表</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="alarmListVisible = false"
        />
      </div>
      <OrderRemarkListInfo :orderNum.sync="selectOrderNumber"/>
    </el-dialog>

    <!--订单日志列表 -->
    <el-dialog
        v-if="orderLogListVisible"
        top="50px"
        :visible.sync="orderLogListVisible"
        :show-close="false" width="90%"
    >
      <div slot="title" class="dialog_header">
        <span>订单日志列表</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderLogListVisible = false"
        />
      </div>
      <orderLogList :orderNum.sync="selectOrderNumber"/>
    </el-dialog>
  </div>
</template>
<script>
import orderApi from "@/api/order";
import {formatTimeToStr} from "@/api/date";
import CarInfo from "@/components/CarInfo";
import OrderInfo from "@/components/OrderInfo";
import OrderAdd from "@/components/OrderAdd";
import OrderEdit from "@/components/OrderEdit";
import OrderMerge from "@/components/OrderMerge";
import orderLogList from "@/components/OrderLog";
import {formatOrderType, isOrderStatus} from "@/api/orderview";
import fenceApi from "@/api/fence";
import VehiclePosition from "@/components/VehiclePosition";
import VehicleTrace from "@/components/VehicleTrace";
import monitor from "@/api/monitor";
import {Message} from "element-ui";
import OrderRemarkListInfo from "@/components/OrderRemarkList";
import {export2File} from "@/api/exp2file";
import {ASSET_MANAGE, checkPermission} from "@/permission";

export default {
  data() {
    return {
      contentHeight: 0,
      searchOption: {
        orderNumber: "",
        carNumber: "",
        carName: "",
        carVin: "",
        orderTime: [],
        realPickTime: [],
        planGiveTime: [],
        orderUser: "",
        warnType: "",
        pageSize: 10,
        cur_page: 1,
        totalCount: 0,
        orderType: "",
        status: "",
        orderCity: "",
        sortby: "",
        order: "",
        isFocus: "",
        isMortgage: '',
        isControl: '',
        isPriorityReminder: '',
        isFirstLease: '',
        isLockCarnumber: '',
        isFirstApplication: '',

      },
      tableData: [],
      cities: [],
      orderInfoVisible: false,
      realTimePosVisible: false,
      orderTraceVisible: false,
      orderAddVisible: false,
      orderEditVisible: false,
      orderMergeVisible: false,
      pageHeight: 0,
      carInfoVisible: false,
      orderLogListVisible: false,
      alarmListVisible: false,
      selectOrderNumber: "",
      selectOrderCity: "",
      inputFocus: "",
      carInfo: {},
      autoOrderMergeTime: 60, //订单合并检测时间秒
      labelTxt: "订单合并(0条)",
      proptype: "", //存放column.prop的字符串值
      mergetimer: null,
      loading: false,
      OPERATE_LIST_P: ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST_P,
      exportLoading: false,
    };
  },
  components: {
    CarInfo,
    OrderInfo,
    VehiclePosition,
    VehicleTrace,
    OrderEdit,
    OrderAdd,
    OrderMerge,
    OrderRemarkListInfo,
    orderLogList,
  },
  computed: {
    showCarInfoFlag() {
      return checkPermission([this.OPERATE_LIST_P.CAR]);
    },
    showOrderInfoFlag() {
      return checkPermission([this.OPERATE_LIST_P.DETAIL, this.OPERATE_LIST_P.EDIT]);
    },
    editOrderInfoFlag() {
      return checkPermission([this.OPERATE_LIST_P.EDIT]);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
      this.pageHeight = document.documentElement.clientHeight;
      fenceApi.findCitys().then((res) => {
        if (res.code == 0) {
          this.cities = res.result;
        }
      });
      this.loadData();
      this.loadOrderMergeData();
      this.autoLoadOrderMerge(); //订单合并
    });
  },
  beforeDestroy() {
    clearInterval(this.mergetimer);
  },
  updated() {

  },
  methods: {
    closeWindowEdit(e) {
      if (e) {
        this.loadData();
      }
      this.orderEditVisible = false;
    },
    closeWindow(e) {
      if (e) {
        this.searchOption.cur_page = 1;
        this.loadData();
      }
      this.orderAddVisible = false;
    },
    loadData() {
      let param = {
        orderNumber: this.searchOption.orderNumber, //订单编号
        carNumber: this.searchOption.carNumber, //车牌号
        carName: this.searchOption.carName, //车辆名称
        carVin: this.searchOption.carVin, //车架号
        orderTimeStart:
            this.searchOption.orderTime && this.searchOption.orderTime.length > 1
                ? this.searchOption.orderTime[0]
                : "", //下单时间—开始
        orderTimeEnd:
            this.searchOption.orderTime && this.searchOption.orderTime.length > 1
                ? this.searchOption.orderTime[1]
                : "", //下单时间—结束
        realPickTimeStart:
            this.searchOption.realPickTime &&
            this.searchOption.realPickTime.length > 1
                ? this.searchOption.realPickTime[0]
                : "", //取车时间—开始
        realPickTimeEnd:
            this.searchOption.realPickTime &&
            this.searchOption.realPickTime.length > 1
                ? this.searchOption.realPickTime[1]
                : "", //取车时间—结束
        planGiveTimeStart:
            this.searchOption.planGiveTime &&
            this.searchOption.planGiveTime.length > 1
                ? this.searchOption.planGiveTime[0]
                : "", //预计还车时间—开始
        planGiveTimeEnd:
            this.searchOption.planGiveTime &&
            this.searchOption.planGiveTime.length > 1
                ? this.searchOption.planGiveTime[1]
                : "", //预计还车时间—结束
        orderUser: this.searchOption.orderUser, //用户姓名
        orderCity: this.searchOption.orderCity, //城市
        status: this.searchOption.status, //订单状态
        isFocus: this.searchOption.isFocus, //是否重点关注
        pageNum: this.searchOption.cur_page, //页码
        pageSize: this.searchOption.pageSize, //每页显示条数
        sortby: this.searchOption.order ? this.searchOption.sortby : '',
        order: this.searchOption.order,
        isMortgage: this.searchOption.isMortgage,
        isControl: this.searchOption.isControl,
        isPriorityReminder: this.searchOption.isPriorityReminder,
        isFirstLease: this.searchOption.isFirstLease,
        isLockCarnumber: this.searchOption.isLockCarnumber,
        isFirstApplication: this.searchOption.isFirstApplication,
        orderType: this.searchOption.orderType,
      };
      //console.log(this.searchOption.cur_page);
      this.loading = true;
      orderApi.getListInMysql(param).then((res) => {
        if (res.code === '0') {
          this.tableData = res.result.list;
          this.searchOption.totalCount = res.result.total;
          this.loadOtherInfo();
        }
        this.$nextTick(() => {
          this.$refs.table.doLayout();
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    loadOtherInfo() {
      this.tableData.forEach((item) => {
        orderApi.getOrderContent({sn: item.orderNumber}).then(res => {
          if (res.code === '0') {
            item.isKeyMonitoring = res.result.isKeyMonitoring;
          }
        });
      });
    },
    ExcelEntity() {
      let param = {
        orderNumber: this.searchOption.orderNumber, //订单编号
        carNumber: this.searchOption.carNumber, //车牌号
        carName: this.searchOption.carName, //车辆名称
        carVin: this.searchOption.carVin, //车架号
        orderTimeStart:
            this.searchOption.orderTime && this.searchOption.orderTime.length > 1
                ? this.searchOption.orderTime[0]
                : "", //下单时间—开始
        orderTimeEnd:
            this.searchOption.orderTime && this.searchOption.orderTime.length > 1
                ? this.searchOption.orderTime[1]
                : "", //下单时间—结束
        realPickTimeStart:
            this.searchOption.realPickTime &&
            this.searchOption.realPickTime.length > 1
                ? this.searchOption.realPickTime[0]
                : "", //取车时间—开始
        realPickTimeEnd:
            this.searchOption.realPickTime &&
            this.searchOption.realPickTime.length > 1
                ? this.searchOption.realPickTime[1]
                : "", //取车时间—结束
        planGiveTimeStart:
            this.searchOption.planGiveTime &&
            this.searchOption.planGiveTime.length > 1
                ? this.searchOption.planGiveTime[0]
                : "", //预计还车时间—开始
        planGiveTimeEnd:
            this.searchOption.planGiveTime &&
            this.searchOption.planGiveTime.length > 1
                ? this.searchOption.planGiveTime[1]
                : "", //预计还车时间—结束
        orderUser: this.searchOption.orderUser, //用户姓名
        orderCity: this.searchOption.orderCity, //城市
        status: this.searchOption.status, //订单状态
        isFocus: this.searchOption.isFocus, //是否重点关注
        isMortgage: this.searchOption.isMortgage,
        isControl: this.searchOption.isControl,
        isPriorityReminder: this.searchOption.isPriorityReminder,
        isFirstLease: this.searchOption.isFirstLease,
        isLockCarnumber: this.searchOption.isLockCarnumber,
        isFirstApplication: this.searchOption.isFirstApplication,
      };
      let myObj = {
        url: process.env.VUE_APP_BASE_API + "/carorder/exportOrderList",
        fileName: "订单列表",
        data: param,
      };
      this.exportLoading = true;
      setTimeout(() => {
        this.exportLoading = false
      }, 15000);
      export2File(myObj);
    },
    tableSortChange(column) {
      this.searchOption.cur_page = 1; //排序后从第一页开始
      // console.log(column)
      // if (column.order === "descending") {
      //   this.searchOption.sortby = column.prop;
      //   this.searchOption.order = "desc";
      // } else {
      //   this.searchOption.sortby = column.prop;
      //   this.searchOption.order = "asc";
      // }
      this.searchOption.sortby = column.prop
      if (column.order == 'descending') {
        this.searchOption.order = 'desc'
      } else if (column.order == 'ascending') {
        this.searchOption.order = 'asc'
      } else {
        this.searchOption.order = ''
      }

      this.loadData();
    },
    viewAlarm(id) {
      //订单备注列表
      this.selectOrderNumber = id;
      this.alarmListVisible = true;
    },
    handleSearch() {
      this.searchOption.cur_page = 1
      this.loadData();
    },
    handleSizeChange(val) {
      this.searchOption.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },
    orderAdd() {
      //订单新增
      this.orderAddVisible = true;
    },
    orderEdit(v) {
      if (!this.showOrderInfoFlag) {
        return;
      }
      //订单修改
      this.selectOrderNumber = v;
      this.orderEditVisible = true;
    },
    orderMerge() {
      //订单合并
      this.orderMergeVisible = true;
    },
    viewOrderLogList(id) {
      this.selectOrderNumber = id;
      this.orderLogListVisible = true;
    },
    showCarInfo(obj) {
      if (!this.showCarInfoFlag) {
        return;
      }
      this.carInfoVisible = true;
      this.carInfo = obj;
    },
    selectOrder(sn) {
      this.selectOrerId = sn;
      this.orderInfoVisible = true;
    },
    autoLoadOrderMerge() {
      let self = this;
      self.mergetimer = setInterval(function () {
        self.loadOrderMergeData();
      }, self.autoOrderMergeTime * 1000);
    },
    updateRemark(row) {
      let param = {
        orderNumber: row.orderNumber,
        remark: row.remark,
      };
      if (row.remark.length > 100) {
        Message.error({
          message: "备注内容最多100个字符！",
        });
      }
      orderApi.editRemark(param).then((res) => {
        if (res.code == 0) {
          this.loadData();
        } else {
          Message.error({
            message: res.msg,
          });
        }
      });
      this.inputFocus = "";
    },
    loadOrderMergeData() {
      monitor.getOrderClashCount().then((res) => {
        if (res.code == 0) {
          this.labelTxt = "订单合并[" + res.result + "]条";
        }
      });
    },
    /**
     * 定位监控的设备
     */
    locationEquipment(v, c) {
      // this.$router.push({path:'/trace',params:{eno:'1111111'}})
      // this.$router.push({path:'/trace',params:{eno:'1111111'}})
      // this.selectOrderNumber = v
      // this.selectOrderCity = c
      // this.realTimePosVisible = true
      // this.fullscreen = true
      //弹出页面效果
      let params = {carNumber: v, city: c, equipNumber: ""};

      const {href} = this.$router.resolve({
        path: "/position",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");
    },
    /**
     * 订单轨迹
     */
    toTrace(obj) {
      // let type = obj.typeAlarm
      // if(type != 4){
      //   this.$router.push({name:'Trace',params:{equipId:obj.macid, pickTime:obj.realPickTime, factory:obj.entity_factory}})
      // }else{
      // this.$router.push({
      //   path: "/property/trace",
      //   query: {
      //     carNumber: obj.carVo.carNumber,
      //     pickTime: obj.realPickTime,
      //     factory: "",
      //   },
      // });
      let params = {
        carNumber: obj.carVo.carNumber,
        pickTime: obj.realPickTime,
        factory: "",
      };
      const {href} = this.$router.resolve({
        path: "/trace",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");

      // }
    },
    // eslint-disable-next-line no-unused-vars
    formatTime(t, c, cellValue, index) {
      return formatTimeToStr(cellValue);
    },
    formatOrder(t, c, cellValue) {
      return isOrderStatus(cellValue);
    },
    timeToStr(t) {
      if (t) {
        return formatTimeToStr(t);
      } else {
        return "--";
      }
    },
    indexMethod(index) {
      let curPage = this.searchOption.cur_page;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
    formatOrderType(v) {
      return formatOrderType(v);
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 120px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.editIcon {
  color: #3b7bcf;
  cursor: pointer;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}

.nopadding .el-dialog__body {
  padding: 0px;
}
</style>
