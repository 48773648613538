<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond">
      <el-input
          v-model="searchOption.carNumber"
          placeholder="车牌号"
          class="handle-input mr10 mt10"
          clearable
      />
      <el-input
          v-model="searchOption.carName"
          placeholder="车辆名称"
          class="handle-input mr10 mt10"
          clearable
      />
      <el-input
          v-model="searchOption.carVin"
          placeholder="车架号"
          class="handle-input mr10 mt10"
          clearable
      />
      <el-select
          v-model="searchOption.city"
          class="handle-select mr10 mt10"
          placeholder="城市"
          clearable
      >
        <el-option
            v-for="item in cities"
            :key="item.id"
            :label="item.name"
            :value="item.name"
        >
          <span style="float: left">
            {{ item.name }}
          </span>
          <span style="float: right; color: #8492a6; font-size: 13px">
            {{ item.name }}
          </span>
        </el-option>
      </el-select>
      <el-select
          v-model="searchOption.status"
          class="handle-select mr10 mt10"
          placeholder="状态"
          clearable
      >
        <el-option key="1" label="运营" value="1"></el-option>
        <el-option key="2" label="下线" value="2"></el-option>
        <el-option key="3" label="采购" value="3"></el-option>
      </el-select>
      <el-select
          v-model="searchOption.isInstock"
          class="handle-select mr10 mt10"
          placeholder="状态"
          clearable
      >
        <el-option key="1" label="在库" value="1"></el-option>
        <el-option key="2" label="出库" value="2"></el-option>
      </el-select>
      <el-button
          type="primary"
          icon="el-icon-search"
          class="ml20 mt10 searchBtn"
          @click="handleSearch()"
      >
        搜索
      </el-button>
    </div>
    <div class="data_list" style="margin-top: 10px">
      <el-table
          ref="refTable"
          v-loading="loading"
          element-loading-text="数据加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          highlight-current-row
          :data="tableData"
          :height="contentHeight - 120"
          :header-cell-style="{
             background: '#FFF',
             color: '#212121'
          }"
          @row-click="clickTable"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.entityList">
              <el-table-column label="设备号" align="left" prop="entityNumber"/>
              <el-table-column label="设备类型" align="left" :formatter="equipTypeFormatSpan"/>
              <el-table-column label="厂商" align="left" :formatter="manufactoryFormat"/>
              <el-table-column label="设备状态" align="left" :formatter="equipStatusFormat"/>
              <el-table-column label="同步时间" align="left" prop="editTime"/>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
            type="index"
            label="序号"
            align="left"
            width="50"
            :index="indexMethod"
        />
        <el-table-column label="车牌号" align="left">
          <template slot-scope="scope">
            {{ scope.row.carNumber }}
          </template>
        </el-table-column>
        <el-table-column label="车辆名称" align="left" prop="carName"/>
        <el-table-column label="车架号" align="left" prop="carVin"/>
        <!-- <el-table-column label="设备名称" align="left" prop="baiduEntityName"/> -->
        <!-- <el-table-column label="MAC" align="left" prop="entityMac"/> -->
        <el-table-column label="所在城市" align="left" prop="carCity"/>
        <el-table-column label="颜色" align="left" prop="carColour"/>
        <el-table-column label="车辆类型" align="left">
          <template slot-scope="scope">
            <div class="verticalCol">
              <span>
                {{ isCarType(scope.row.carType) }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="同步时间" align="left" prop="editTime"/>
        <el-table-column label="状态" align="left" prop="status" :formatter="equipStatusFormat"/>
        <el-table-column label="是否在库" align="left" prop="isInstock" :formatter="equipisInstockFormat"/>
        <!--
        <el-table-column label="操作" align="left">
          <template slot-scope="scope">
            <el-row>
              <el-col :span="12">
                <el-button type="text" icon="el-icon-open" v-if="scope.row.status == 2" class="btnOnline">在线</el-button>
                <el-button type="text" icon="el-icon-open" v-else class="btnOutLine">离线</el-button>
              </el-col>
              <el-col :span="12">
                <el-button type="text" icon="el-icon-paperclip">绑定围栏</el-button>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
        -->
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next,total"
            :total="searchOption.totalCount"
            :page-size="searchOption.pageSize"
            :current-page.sync="searchOption.cur_page"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <!--      车辆信息-->
    <el-dialog
        v-if="carInfoVisible"
        width="60%"
        :visible.sync="carInfoVisible"
        :show-close="false"
    >
      <div slot="title" class="dialog_header">
        <span>车辆信息</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="carInfoVisible = false"
        />
      </div>
      <CarInfo :carInfoObj="carInfo"/>
    </el-dialog>
  </div>
</template>
<script>
import carApi from "@/api/car";
import fenceApi from "@/api/fence";
import CarInfo from "@/components/CarInfo";
import {isCarType} from "@/api/carview";

export default {
  data() {
    return {
      contentHeight: 0,
      searchOption: {
        carName: "",
        carVin: "",
        status: "1",
        pageSize: 10,
        cur_page: 1,
        totalCount: 0,
        offline: "",
        carNumber: "",
        entityType: "",
        city: "",
        isInstock: "1",
      },
      tableData: [],
      cities: [],
      carInfoVisible: false,
      carInfo: {},
      loading: false,
    };
  },
  components: {CarInfo},
  mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
      this.contentHeight = this.$refs.page.offsetHeight;
      this.pageHeight = document.documentElement.clientHeight;
      fenceApi.findCitys().then((res) => {
        if (res.code == 0) {
          this.cities = res.result;
        }
      });
    });
    this.loadData();
  },
  methods: {
    handleSearch() {
      this.searchOption.cur_page = 1;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },
    loadData() {
      let param = {
        pageNum: this.searchOption.cur_page,
        pageSize: this.searchOption.pageSize,
        carName: this.searchOption.carName, //车辆名称
        carVin: this.searchOption.carVin, //车架号
        status: this.searchOption.status, //车辆状态
        carNumber: this.searchOption.carNumber, //车牌号
        carCity: this.searchOption.city,
        isInstock: this.searchOption.isInstock,//车辆在库状态
      };
      this.loading = true;
      carApi.getListInMysql(param).then((res) => {
        if (res.code == 0) {
          this.tableData = res.result.list;
          this.searchOption.totalCount = res.result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    equipStatusFormat(row) {
      //status 设备状态 1-使用中 2-无效
      if (row.status == 1) {
        return (
            <el-tag effect="dark" type="success">
              有效
            </el-tag>
        );
      } else if (row.status == 2) {
        return (
            <el-tag effect="dark" type="info">
              无效
            </el-tag>
        );
      } else {
        return <span>--</span>;
      }
    },
    equipisInstockFormat(row) {
      //is_instock 是否在库，1：在库，2 出库
      if (row.isInstock == 1) {
        return (
            <el-tag effect="dark" type="success">
              在库
            </el-tag>
        );
      } else if (row.isInstock == 2) {
        return (
            <el-tag effect="dark" type="info">
              出库
            </el-tag>
        );
      } else {
        return <span>--</span>;
      }
    },
    isCarType(v) {
      return isCarType(v);
    },
    equipOfflineFormat(row) {
      //offline 是否在线，1：离线，2 在线
      if (row.offline == 1) {
        return (
            <el-tag effect="dark" type="success">
              运营
            </el-tag>
        );
      } else if (row.offline == 2) {
        return (
            <el-tag effect="dark" type="info">
              下线
            </el-tag>
        );
      } else {
        return <span>--</span>;
      }
    },
    //  equipTypeFormatSpan(row){
    //    return equipTypeFormatSpan(row)
    //  },
    equipTypeFormatSpan(row) {
      if (row.entityType == 1) {
        return <span class="etype">OBD</span>;
      } else if (row.entityType == 3) {
        return <span class="etype">有线</span>;
      } else if (row.entityType == 2) {
        return <span class="etype">无线</span>;
      } else if (row.entityType == 4) {
        return <span class="etype">断油电</span>;
      } else if (row.entityType == 5) {
        return <span class="etype">静默</span>;
      } else {
        return <span>--</span>;
      }
    },
    manufactoryFormat(row) {
      if (row.entityFactory == 1) {
        return <span>四海行</span>;
      } else if (row.entityFactory == 2) {
        return <span>艾能</span>;
      } else if (row.entityFactory == 3) {
        return <span>自有硬件</span>;
      } else if (row.entityFactory == 4) {
        return <span>中瑞</span>;
      } else if (row.entityFactory == 5) {
        return <span>赛格</span>
      } else {
        return <span>无</span>;
      }
    },
    indexMethod(index) {
      let curPage = this.searchOption.cur_page;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
    showCarInfo(obj) {
      this.carInfoVisible = true;
      this.carInfo = obj;
    },
    // eslint-disable-next-line no-unused-vars
    clickTable(row, column, event) {
      this.$refs.refTable.toggleRowExpansion(row);
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 120px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.etype {
  font-weight: bold;
  color: #212121;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.btnOnline {
  color: #3b7bcf;
}

.btnOutLine {
  color: #8c939d;
}

.equipList {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.equipList span {
  color: #666666;
  font-size: 16px;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}
</style>
