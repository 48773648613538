/**
 * 操按钮权限处理
 */
import {checkPermission} from "@/permission";

export default {
  inserted(el, binding) {
    const {value} = binding
    if (value
        && value instanceof Array
        && value.length > 0
    ) {
      if (!checkPermission(value)) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error(`请设置操作权限标签值`);
    }
  }
}
