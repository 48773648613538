<template>
  <div ref="page" class="data_wrap">
    <!--查询-->
    <div class="search_cond">
      <el-input
          v-model="searchOption.name"
          placeholder="电子围栏名称"
          clearable
          class="handle-input mr10 mt10"
      />
      <el-select
          v-model="searchOption.type"
          class="handle-select mr10 mt10"
          clearable
          placeholder="围栏类型"
      >
        <el-option key="0" label="圆形" value="1"/>
        <el-option key="1" label="多边形" value="2"/>
      </el-select>
      <el-select
          v-model="searchOption.city"
          class="handle-select mr10 mt10"
          placeholder="城市"
          clearable
      >
        <el-option
            v-for="item in cities"
            :key="item.id"
            :label="item.name"
            :value="item.name"
        >
          <span style="float: left">
            {{ item.name }}
          </span>
        </el-option>
      </el-select>
      <el-button
          type="primary"
          icon="el-icon-search"
          class="ml20 mt10 searchBtn"
          @click="searchFence"
      >
        搜索
      </el-button>
      <el-button
          v-has-permission="[FENCE_SETTING.ADD]"
          type="primary"
          icon="el-icon-plus"
          class="ml20 mt10 searchBtn"
          @click="toAdd"
      >
        添加
      </el-button>
    </div>

    <!--数据列表-->
    <div class="data_list">
      <el-table
          highlight-current-row
          :data="tableData"
          :style="'height:' +(contentHeight - 120) +'px;width: 100%;overflow-y:auto;margin-top:10px'"
          :header-cell-style="{ background: '#FFF', color: '#212121' }"
          @sort-change="tableSortChange"
      >
        <el-table-column type="index" :index="indexMethod" label="序号" align="left" width="50"/>
        <el-table-column prop="fenceName" label="围栏名称" align="left"/>
        <el-table-column prop="shape" label="围栏类型" align="left" :formatter="fenceTypeFormat"/>
        <el-table-column prop="city" label="城市" align="left"/>
        <el-table-column prop="creator" label="创建人" align="left"/>
        <el-table-column prop="createDate" sortable="custom" label="创建时间" align="left"/>
        <el-table-column prop="coordType" label="坐标类型" align="left" :formatter="fenceCoordTypeFormat"/>
        <el-table-column label="操作" align="left">
          <template slot-scope="scope">
            <el-row>
              <el-col :span="12">
                <el-button
                    v-has-permission="[FENCE_SETTING.EDIT, FENCE_SETTING.DETAIL]"
                    type="text"
                    icon="el-icon-location-information"
                    @click="toDetail(scope.row)"
                >
                  详情
                </el-button>
              </el-col>
              <el-col :span="12">
                <el-button
                    v-has-permission="[FENCE_SETTING.DELETE]"
                    type="text"
                    icon="el-icon-minus"
                    @click="deleteFence(scope.row.fenceId, scope.row.fenceName)"
                >
                  删除
                </el-button>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-button
                    v-has-permission="[FENCE_SETTING.DEVICE_LIST]"
                    type="text"
                    icon="el-icon-tickets"
                    @click="viewEquip(scope.row.fenceId)"
                >
                  设备列表
                </el-button>
              </el-col>
              <el-col :span="12">
                <el-button
                    v-has-permission="[FENCE_SETTING.BIND]"
                    type="text"
                    icon="el-icon-paperclip"
                    @click="toBindEquip(scope.row.fenceId)"
                >
                  绑定设备
                </el-button>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next,total"
            :total="searchOption.totalCount"
            :current-page.sync="currentPage"
            :page-size="20"
            @current-change="handleCurrentChange"
        />
      </div>

      <!--   设备-->
      <el-dialog
          v-if="equipListVisible"
          :visible.sync="equipListVisible"
          :show-close="false"
          width="40%"
      >
        <div slot="title" class="dialog_header">
          <span>围栏设备</span>
          <i
              class="el-icon-close"
              style="font-size: 26px"
              @click="equipListVisible = false"
          />
        </div>
        <EquipmentInFence
            :fenceId.sync="selectFenceId"
            @success="onAddSuccess"
        />
      </el-dialog>
      <!--  绑定 设备-->
      <el-dialog
          v-if="equipBindVisible"
          :visible.sync="equipBindVisible"
          :show-close="false"
          width="450px"
          style="height: 80%"
      >
        <div slot="title" class="dialog_header">
          <span>绑定设备</span>
          <i
              class="el-icon-close"
              style="font-size: 26px"
              @click="equipBindVisible = false"
          />
        </div>
        <el-radio-group v-model="entityFactory" size="medium">
          <el-radio-button label="1">四海行</el-radio-button>
          <el-radio-button label="2">艾能</el-radio-button>
          <el-radio-button label="3">自有硬件</el-radio-button>
          <el-radio-button label="4">中瑞</el-radio-button>
          <el-radio-button label="5">赛格</el-radio-button>
        </el-radio-group>
        <el-input
            v-model="bindEquipSn"
            placeholder="绑定设备标识码"
            clearable
            style="width: 303px"
            class="handle-input mr10 mt10"
        />
        <el-button
            type="primary"
            icon="el-icon-paperclip"
            class="ml20 searchBtn"
            @click="bindEquipment"
        >
          绑定
        </el-button>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import EquipmentInFence from "@/components/EquipmentInFence";
import fenceApi from "@/api/fence";
import equipApi from "@/api/equipment";
import {SYSTEM_SETTING} from "@/permission"
import {Message} from "element-ui";

export default {
  data() {
    return {
      contentHeight: 0,
      searchOption: {
        name: "",
        type: "",
        totalCount: 0,
        city: "",
        orderByTime: '0'
      },
      tableData: [],
      equipBindVisible: false,
      addfenceDetailVisible: false,
      equipListVisible: false,
      bindEquipSn: "",
      currentPage: 1,
      selectFenceId: "",
      cities: [],
      entityFactory: "",
      FENCE_SETTING: SYSTEM_SETTING.FENCE_SETTING
    };
  },
  components: {EquipmentInFence},
  mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
      this.currentPage = 1;
      fenceApi.findCitys().then((res) => {
        if (res.code == 0) {
          this.cities = res.result;
        }
      });
      this.loadData();
    });
  },
  methods: {
    tableSortChange(column) {
      this.searchOption.cur_page = 1; //排序后从第一页开始

      this.searchOption.sortby = column.prop
      if (column.order == 'descending') {
        this.searchOption.orderByTime = '1'
      } else if (column.order == 'ascending') {
        this.searchOption.orderByTime = '0'
      } else {
        this.searchOption.orderByTime = '0'
      }

      this.searchFence();
    },
    loadData() {
      let self = this;
      fenceApi.findAll(this.currentPage, 20).then((res) => {
        if (res && res.code == "0") {
          self.searchOption.totalCount = res.result.total;
          self.tableData = res.result.list;
        }
      });
    },
    searchFence() {
      let self = this;
      this.currentPage = 1;
      let param = {
        pageNum: this.currentPage,
        pageSize: 20,
        orderByTime: this.searchOption.orderByTime
      };
      if (this.searchOption.name.length > 0) {
        param.fenceName = this.searchOption.name;
      }
      if (this.searchOption.type.length > 0) {
        param.shape = this.searchOption.type;
      }
      if (this.searchOption.city.length > 0) {
        param.city = this.searchOption.city;
      }
      fenceApi.findByCondition(param).then((res) => {
        if (res && res.code == "0") {
          self.searchOption.totalCount = res.result.total;
          self.tableData = res.result.list;
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.loadData();
    },
    toDetail(data) {
      let para = JSON.stringify(data);
      this.$router.push({
        path: "/system/electricFence/detail",
        query: {
          para: encodeURIComponent(para),
        },
      });
    },
    deleteFence(id, name) {
      let self = this;
      self
          .$confirm("确认删除围栏[" + name + "]？")
          .then(() => {
            fenceApi.remove({fenceId: id}).then((res) => {
              if (res && res.code == "0") {
                Message.success({
                  message: "删除成功",
                });
                self.loadData();
              } else {
                Message.error({
                  message: res.msg,
                });
              }
            });
          })
          .catch(() => {
          });
    },
    toAdd() {
      this.$router.push("/system/electricFence/add");
    },
    fenceTypeFormat(row) {
      if (row.shape == 1) {
        return <span class="etype">圆形</span>;
      } else if (row.shape == 2) {
        return <span class="etype">多边形</span>;
      } else {
        return <span>--</span>;
      }
    },
    fenceCoordTypeFormat(row) {
      if (row.coordType == "wgs84") {
        return <span class="etype">GPS经纬度</span>;
      } else if (row.coordType == "gcj02") {
        return <span class="etype">国测局经纬度</span>;
      } else if (row.coordType == "bd09ll") {
        return <span class="etype">百度经纬度</span>;
      } else {
        return <span>--</span>;
      }
    },
    viewEquip(id) {
      this.selectFenceId = id;
      this.equipListVisible = true;
    },
    toBindEquip(id) {
      this.selectFenceId = id;
      this.equipBindVisible = true;
      this.bindEquipSn = "";
    },
    bindEquipment() {
      // bindEquipSn
      let param = {
        entityFactory: this.entityFactory,
        entityNumber: this.bindEquipSn,
        fenceId: this.selectFenceId,
      };
      equipApi.bindEquip(param).then((res) => {
        if (res && res.code == "0") {
          Message.success({
            message: "绑定成功",
          });
          self.loadData();
        } else {
          Message.error({
            message: res.msg,
          });
        }
        this.loadData();
      });
    },
    onAddSuccess() {
      this.equipListVisible = false;
      this.searchFence();
    },
    indexMethod(index) {
      let curPage = this.currentPage;
      let pageSize = 20;
      return index + 1 + (curPage - 1) * pageSize;
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 200px;
  display: inline-block;
}

.handle-select {
  /*width: 120px;*/
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}

.el-table::before {
  z-index: inherit;
}
</style>
