<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond">
      <el-date-picker
          v-model="dateTime"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
      />

      <el-select
          v-model="searchOption.hour"
          class="handle-select ml10 mr10 mt10"
          placeholder="查询小时"
          clearable
      >
        <el-option key="0" label="0" value="0"></el-option>
        <el-option key="1" label="1" value="1"></el-option>
        <el-option key="2" label="2" value="2"></el-option>
        <el-option key="3" label="3" value="3"></el-option>
        <el-option key="4" label="4" value="4"></el-option>
        <el-option key="5" label="5" value="5"></el-option>
        <el-option key="6" label="6" value="6"></el-option>
        <el-option key="7" label="7" value="7"></el-option>
        <el-option key="8" label="8" value="8"></el-option>
        <el-option key="9" label="9" value="9"></el-option>
        <el-option key="10" label="10" value="10"></el-option>
        <el-option key="11" label="11" value="11"></el-option>
        <el-option key="12" label="12" value="12"></el-option>
        <el-option key="13" label="13" value="13"></el-option>
        <el-option key="14" label="14" value="14"></el-option>
        <el-option key="15" label="15" value="15"></el-option>
        <el-option key="16" label="16" value="16"></el-option>
        <el-option key="17" label="17" value="17"></el-option>
        <el-option key="18" label="18" value="18"></el-option>
        <el-option key="19" label="19" value="19"></el-option>
        <el-option key="20" label="20" value="20"></el-option>
        <el-option key="21" label="21" value="21"></el-option>
        <el-option key="22" label="22" value="22"></el-option>
        <el-option key="23" label="23" value="23"></el-option>
      </el-select>

      <el-select
          v-model="searchOption.sort"
          class="handle-select ml10 mr10 mt10"
          placeholder="查询小时"
          clearable
      >
        <el-option key="0" label="升序" value="0"></el-option>
        <el-option key="1" label="降序" value="1"></el-option>
      </el-select>
      柱图个数：
      <el-input-number
          v-model="searchOption.number"
          style="width: 60px"
          :min="0"
          :controls="false"
      />
      <el-button
          type="primary"
          icon="el-icon-search"
          class="ml20 mt10 searchBtn"
          @click="handleSearch()"
      >
        搜索
      </el-button>
      <el-button
          v-has-permission="[CAR_SUMMARY.EXPORT]"
          type="success"
          class="cell_btn iconfont icon-daochu"
          :loading="expLoading"
          @click="ExcelEntity()"
      >
        导出
      </el-button>
    </div>
    <div style="margin-top: 10px">
      查询日期: {{ searchTime }}
    </div>
    <div class="data_list" style="margin-top: 10px">
      <el-table
          ref="table"
          v-loading="isLoading"
          highlight-current-row
          height="300"
          :data="tableData"
          :header-cell-style="{
            background: '#FFF',
            color: '#212121'
          }"
      >
        <!-- <el-table-column width="220" label="日期" align="left" prop="date" /> -->
        <el-table-column label="城市" align="left" prop="city" width="200"/>
        <el-table-column label="车辆数" align="left" prop="carNum" width="200"/>
        <el-table-column label="车牌明细" align="left" prop="carInfo" show-overflow-tooltip/>
        <!-- <el-table-column :label="item.name" :prop="item.dataKey" :key="item.dataKey" align="left"
          v-for="item in dataList" /> -->
      </el-table>
      <BarChart ref="BarChart"/>
    </div>

    <!-- 车辆信息 好像没有用-->
    <el-dialog
        v-if="carInfoVisible"
        :visible.sync="carInfoVisible"
        :show-close="false"
        width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>车辆信息</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="carInfoVisible = false"
        />
      </div>
      <CarInfo :carInfoObj="carInfo"/>
    </el-dialog>
  </div>
</template>
<script>
import BarChart from './barchart.vue'
import CarInfo from "@/components/CarInfo";
import {export2File} from "@/api/exp2file";
import {cityCarGather} from "@/api/statistics/cityCar";
import {REPORT_FORMS} from "@/permission";

export default {
  data() {
    return {
      contentHeight: 0,
      searchOption: {
        date: "",
        hour: "",
        sort: '1',
        number: 10
      },
      tableData: [],
      carInfoVisible: false,
      carInfo: {},
      isLoading: false,
      cityNum: 0,
      dataList: [],
      dateTime: [],
      searchTime: '',
      CAR_SUMMARY: REPORT_FORMS.CAR_SUMMARY,
      expLoading: false
    };
  },
  components: {CarInfo, BarChart},
  updated() {
  },
  mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
    });
    // this.hourValue =
    //   new Date().getHours() - 1 < 0 ? 0 + "" : new Date().getHours() - 1 + "";
    this.dateTime = [this.format(new Date()), this.format(new Date())];
    this.loadData();

  },
  methods: {
    format(dat) {
      //获取年月日，时间
      var year = dat.getFullYear();
      var mon =
          dat.getMonth() + 1 < 10
              ? "0" + (dat.getMonth() + 1)
              : dat.getMonth() + 1;
      var data = dat.getDate() < 10 ? "0" + dat.getDate() : dat.getDate();
      var hour = dat.getHours();
      this.searchOption.hour = hour - 1 < 0 ? 0 + "" : hour - 1 + "";
      var newDate = year + "-" + mon + "-" + data;
      return newDate;
    },
    handleSearch() {
      this.searchOption.cur_page = 1;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },
    loadData() {
      if (this.searchOption.hour == '') {
        this.$message.warning('请输入小时')
        return false
      }
      this.isLoading = true;
      let param = {
        hour: this.searchOption.hour,
        startDate: Array.isArray(this.dateTime) && this.dateTime.length > 0 ? this.dateTime[0] : '',
        endDate: Array.isArray(this.dateTime) && this.dateTime.length > 0 ? this.dateTime[1] : '',
        sort: this.searchOption.sort,
        type: "2",
      };
      cityCarGather(param).then((res) => {
        if (res.code == 0 && res.result && res.result.dataJson) {
          let dataJson = res.result.dataJson;
          let list = []
          dataJson.forEach(i => {
            list.push({
              city: i.name,
              carNum: i.count,
              carInfo: i.carNums
            })
          })
          // dataJson.forEach((i, index) => {
          //   data["s" + index] = i.count;
          // });
          if (this.dateTime.length > 0) {

            if (this.dateTime[1] == this.dateTime[0]) {
              this.searchTime = this.dateTime[0] + ' ' + this.searchOption.hour + ':00:00';
            } else {
              this.searchTime = this.dateTime[0] + ' ~ ' + this.dateTime[1] + ' ' + this.searchOption.hour + ':00:00';
            }
          } else {
            this.searchTime = '';
          }
          // console.log(data)
          // data["city"] = "车辆数";
          this.dataList = dataJson.map((i, index) => {
            return {
              name: i.name,
              dataKey: "s" + index,
            };
          });
          let chartData = dataJson.splice(0, this.searchOption.number)
          this.$refs.BarChart.drawLine({
            timeList: chartData.map(i => i.name),
            dataList: chartData.map(i => i.count)
          });
          this.tableData = list;
        }
        this.$nextTick(() => {
          this.$refs.table.doLayout();
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    /**
     *
     */
    ExcelEntity() {
      if (this.searchOption.hour == '') {
        this.$message.warning('请输入小时')
        return false
      }
      let param = {
        hour: this.searchOption.hour,
        startDate: Array.isArray(this.dateTime) && this.dateTime.length > 0 ? this.dateTime[0] : '',
        endDate: Array.isArray(this.dateTime) && this.dateTime.length > 0 ? this.dateTime[1] : '',
        sort: this.searchOption.sort,
        type: "2",
      };
      let myObj = {
        url: process.env.VUE_APP_BASE_API + "/statistics/cityCarGatherExport",
        fileName: "城市车辆",
        data: param,
      };
      this.expLoading = true
      setTimeout(() => {
        this.expLoading = false
      }, 4000);
      export2File(myObj);
    },
    showCarInfo(obj) {
      this.carInfoVisible = true;
      this.carInfo = obj;
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 120px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.ExcelBtn {
  height: 30px;
  background: #3dd123;
}

.etype {
  font-weight: bold;
  color: #212121;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.btnOnline {
  color: #3b7bcf;
}

.btnOutLine {
  color: #8c939d;
}

.ml20 {
  margin-left: 20px;
}

.mt10 {
  margin-top: 10px;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}

.el-tooltip__popper {
  width: 40vw;
}
</style>
