import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import './router/resolve'
import store from './store/index'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css' // 默认主题
import BaiduMap from 'vue-baidu-map'
import VueWechatTitle from 'vue-wechat-title'//动态修改title
import dataV from '@jiaminghi/data-view'
import generalComponents from '@/components/general'
//自定义指令之按钮权
import importDirective from '@/directive';

importDirective(Vue);

Vue.use(generalComponents)

Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small'
})
Vue.prototype.$axios = axios
Vue.use(dataV)
Vue.use(BaiduMap, {
  /* Visit http://lbsyun.baidu.com/apiconsole/key for details about app key. */
  ak: 'aOQ33UaQa5A5mNKKwM5NqvOHXFzvIqT9'
})
Vue.use(VueWechatTitle)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
