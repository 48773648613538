import {requestLogin, requestAutoLogin, getSysUser} from "@/api/system/user";
import {setToken} from '@/utils/auth'

const user = {
  state: {
    nickName: '',
    permissions: [],
    menuActive: ''

  },
  mutations: {
    setUserInfo(state, e) {
      state.nickName = e.nickName
    },
    setUserInfo1() {
    },
    setMenuActive(state, e) {
      state.menuActive = e
    },
    setPermissions(state, e) {
      state.permissions = e
    }
  },
  actions: {
    login({commit}, e) {
      return new Promise((resolve, reject) => {
        requestLogin(e).then(res => {
          if (res.code == 0) {
            commit('setUserInfo1')
            setToken(res.result.token)
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    autoLogin({commit}, e) {
      return new Promise((resolve, reject) => {
        requestAutoLogin(e).then(res => {
          if (res.code == 0) {
            commit('setUserInfo1')
            setToken(res.result.token)
          }
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    getUserInfo({commit}) {
      return new Promise((resolve, reject) => {
        getSysUser({}).then(res => {
          if (res.code == 0) {
            commit('setUserInfo', res.result);
            const permissions = res.result.sysRole.permissions.filter(item => item);
            commit('setPermissions', permissions);
            resolve(res.result.sysRole.sysMenuList)
          } else {
            reject()
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    setMenuActive({commit}, e) {
      commit('setMenuActive', e)
    }
  }
}

export default user
