<template>
  <div class="data_wrap">
    <el-tabs v-model="currentView">
      <el-tab-pane
          v-if="operateShow"
          label="运营订单"
          name="order"
      >
      </el-tab-pane>
      <el-tab-pane
          v-if="unOperateShow"
          label="非运营订单"
          name="proOrder"
      >
      </el-tab-pane>
      <el-tab-pane
          v-if="storageShow"
          label="在库车辆"
          name="inCar"
      >
      </el-tab-pane>
    </el-tabs>
    <!--:is 的作用：会将div标签转换成 currentView 变量绑定的这个组件-->
    <div
        :is="currentView"
        v-if="listShow"
        keep-alive
    >
    </div>
  </div>
</template>

<script>
import {DATA_MONITORING, checkPermission} from "@/permission";

export default {
  components: {
    //以下方式引入路由是路由的懒加载，有利于页面优化
    order: (resolve) => {
      require(["./components/VehicleStatusHistory.vue"], resolve);
    },
    proOrder: (resolve) => {
      require(["./components/VehicleStatusPreOrderHistory.vue"], resolve);
    },
    inCar: (resolve) => {
      require(["./components/VehicleStatusInCarHistory.vue"], resolve);
    },
  },
  data() {
    return {
      currentView: "order", //当前组件
      ALARM_HISTORY: DATA_MONITORING.ALARM_HISTORY
    };
  },
  computed: {
    operateShow() {
      return checkPermission([this.ALARM_HISTORY.OPERATE_LIST]);
    },
    unOperateShow() {
      return checkPermission([this.ALARM_HISTORY.UN_OPERATE_LIST]);
    },
    storageShow() {
      return checkPermission([this.ALARM_HISTORY.STORAGE_CAR_LIST]);
    },
    listShow() {
      if (this.currentView === "order") {
        return this.operateShow;
      }
      if (this.currentView === "proOrder") {
        return this.unOperateShow;
      }
      if (this.currentView === "inCar") {
        return this.storageShow;
      }
      return false;
    }
  },
  methods: {},
  created() {
  },
  mounted() {
    if (this.operateShow) {
      this.currentView = "order";
      return;
    }
    if (this.unOperateShow) {
      this.currentView = "proOrder";
      return;
    }
    if (this.storageShow) {
      this.currentView = "inCar";
    }
  }
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>
