<template>
  <div ref="page" class="data_wrap">
    <div class="search_cond">
      <el-input
          v-model="searchOption.orderNumber"
          placeholder="订单编号"
          class="handle-input mr10 mt10"
          clearable
      />
      <el-input
          v-model="searchOption.vehicleSn"
          placeholder="车牌号"
          class="handle-input mr10 mt10"
          clearable
      />
      <el-input
          v-model="searchOption.carName"
          placeholder="车辆名称"
          class="handle-input mr10 mt10"
          clearable
      />
      <el-input
          v-model="searchOption.carVin"
          placeholder="车架号"
          class="handle-input mr10 mt10"
          clearable
      />
      <el-select
          v-model="searchOption.orderType"
          class="handle-select mr10 mt10"
          placeholder="车辆事由"
          clearable
      >
        <el-option key="1" label="整备" value="1"></el-option>
        <el-option key="2" label="维保" value="2"></el-option>
        <el-option key="3" label="调车" value="3"></el-option>
        <el-option key="4" label="摆渡车" value="4"></el-option>
        <el-option key="5" label="公务车" value="5"></el-option>
        <el-option key="6" label="加油/补电" value="6"></el-option>
        <el-option key="7" label="车辆清洗" value="7"></el-option>
        <el-option key="8" label="检修" value="8"></el-option>
        <el-option key="9" label="试车" value="9"></el-option>
        <el-option key="10" label="定损" value="10"></el-option>
        <el-option key="11" label="检车" value="11"></el-option>
        <el-option key="12" label="贴膜" value="12"></el-option>
        <el-option key="99" label="其它" value="99"></el-option>
      </el-select>
      出库时间：
      <el-date-picker
          v-model="startTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
      />
      入库时间：
      <el-date-picker
          v-model="endTime"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
      />
      <!--
      <el-input
        v-model="searchOption.userName"
        placeholder="用户姓名"
        class="handle-input mr10 mt10"
      ></el-input>
      -->
      <el-select
          v-model="searchOption.city"
          class="handle-select mr10 mt10"
          placeholder="城市"
          clearable
      >
        <el-option
            v-for="item in cities"
            :key="item.id"
            :label="item.name"
            :value="item.name"
        >
          <span style="float: left">
            {{ item.name }}
          </span>
          <span style="float: right; color: #8492a6; font-size: 13px">
            {{ item.name }}
          </span>
        </el-option>
      </el-select>
      <el-select
          v-model="searchOption.orderStatus"
          class="handle-select mr10 mt10"
          placeholder="订单状态"
          clearable
      >
        <el-option key="1" label="出库" value="1"></el-option>
        <el-option key="2" label="入库" value="2"></el-option>
      </el-select>
      <el-button
          type="primary"
          icon="el-icon-search"
          class="ml20 mt10 searchBtn"
          @click="handleSearch()"
      >
        搜索
      </el-button>
      <el-button
          v-has-permission="[UN_OPERATE_LIST_P.ADD]"
          type="primary"
          icon="el-icon-plus"
          class="ml20 mt10 searchBtn"
          @click="orderAdd()"
      >
        新增
      </el-button>
      <!--
      <el-button
        type="primary"
        ref="btn_hb"
        icon="el-icon-document"
        @click="orderMerge()"
        class="ml20 mt10 searchBtn"
        >{{ labelTxt }}</el-button
      > -->
      <el-button
          v-has-permission="[UN_OPERATE_LIST_P.EXPORT]"
          type="success"
          class="cell_btn iconfont icon-daochu"
          :loading="exportLoading"
          @click="ExcelEntity()"
      >
        导出订单
      </el-button>
    </div>
    <div class="data_list" style="margin-top: 10px">
      <el-table
          ref="table"
          v-loading="loading"
          element-loading-text="数据加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          highlight-current-row
          :data="tableData"
          :height="contentHeight - 120"
          :header-cell-style="{
            background: '#FFF',
            color: '#212121'
          }"
          @sort-change="tableSortChange"
      >
        <el-table-column type="index" label="序号" :index="indexMethod" align="left" width="50"/>
        <el-table-column label="订单编号/订单类型" align="left" width="160">
          <template slot-scope="scope">
            <el-button
                type="text"
                style="user-select: unset"
                @click="orderEdit(scope.row.orderNumber)"
            >
              {{ scope.row.orderNumber }}
            </el-button>
            <div>[{{ formatNopreOrderType(scope.row.orderType) }}]</div>
          </template>
        </el-table-column>
        <el-table-column label="车牌号" align="left" width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="showCarInfo(scope.row.carVo)">
              {{ scope.row.carVo.carNumber }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="carVo.carName" label="车辆名称" width="160" align="left"/>
        <el-table-column prop="carVin" label="车架号" width="160" align="left"/>
        <!--
        <el-table-column label="用户姓名" align="left" >
          <template slot-scope="scope">
            <el-button type="text" >{{scope.row.orderUser}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="orderUser" label="客户姓名" align="left" />
        <el-table-column
          prop="orderTel"
          label="电话"
          align="left"
          width="140"
        />
        -->
        <el-table-column prop="orderCity" label="城市" align="left"/>
        <el-table-column prop="insertTime" label="出库时间" align="left" width="160"/>
        <el-table-column label="入库时间" prop="endTime" align="left" width="160">
          <template slot-scope="scope">
            <div class="verticalCol">
              <span>{{ timeToStr(scope.row.endTime) }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="orderMil" label="订单里程" align="left"/>
        <el-table-column prop="status" label="订单状态" align="left" :formatter="formatOrder" show-overflow-tooltip/>
        <el-table-column label="备注" align="left" width="200">
          <template slot-scope="scope">
            <div class="verticalRow">
              <span v-if="inputFocus != scope.row.orderId">
                {{ scope.row.remark }}
              </span>
              <el-input
                  v-if="inputFocus == scope.row.orderId"
                  v-model="scope.row.remark"
                  type="textarea"
                  :autosize="{ minRows: 1, maxRows: 3 }"
                  @blur="updateRemark(scope.row)"
              />
              <i
                  v-has-permission="[UN_OPERATE_LIST_P.REMARK_EDIT]"
                  class="el-icon-edit editIcon label"
                  @click="inputFocus = scope.row.orderId"
              >
                备注
              </i>
              <i
                  v-has-permission="[UN_OPERATE_LIST_P.REMARK_DETAIL]"
                  class="el-icon-search editIcon label"
                  @click="viewAlarm(scope.row.orderNumber)"
              >
                查看
              </i>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" fixed="right" width="160">
          <template slot-scope="scope">
            <el-row>
              <el-col :span="8">
                <el-button
                    v-has-permission="[UN_OPERATE_LIST_P.LOCATION]"
                    type="text"
                    icon="el-icon-location-information"
                    @click="locationEquipment(scope.row.carVo.carNumber,scope.row.orderCity)"
                >
                  位置
                </el-button>
              </el-col>
              <el-col :span="8">
                <el-button
                    v-has-permission="[UN_OPERATE_LIST_P.TRACE]"
                    type="text"
                    icon="el-icon-aim"
                    @click="toTrace(scope.row)"
                >
                  轨迹
                </el-button>
              </el-col>
              <!--
              <el-col :span="8">
                <el-button type="text" icon="el-icon-edit" @click="orderEdit(scope.row.orderNumber)">修改</el-button>
              </el-col>
              -->
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="prev, pager, next,total"
            :total="searchOption.totalCount"
            :page-size="searchOption.pageSize"
            :current-page.sync="searchOption.cur_page"
            @current-change="handleCurrentChange"
        />
      </div>
    </div>

    <!--订单信息-->
    <el-dialog
        v-if="orderInfoVisible"
        :visible.sync="orderInfoVisible"
        :show-close="false"
        width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>订单信息</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderInfoVisible = false"
        />
      </div>
      <OrderInfo :orderId="selectOrerId"/>
    </el-dialog>

    <!--车辆信息-->
    <el-dialog
        v-if="carInfoVisible"
        width="60%"
        :visible.sync="carInfoVisible"
        :show-close="false"
    >
      <div slot="title" class="dialog_header">
        <span>车辆信息</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="carInfoVisible = false"
        />
      </div>
      <CarInfo :carInfoObj="carInfo"/>
    </el-dialog>

    <!-- 车辆实时位置-->
    <el-dialog
        v-if="realTimePosVisible"
        :visible.sync="realTimePosVisible"
        :show-close="false"
        :fullscreen="fullscreen"
        :class="fullscreen ? 'nopadding dialogFullSc' : 'nopadding dialogLessSc'"
    >
      <div slot="title" class="dialog_header">
        <span>车辆实时位置</span>
        <div>
          <i
              class="iconfont icon-zuixiaohua_huaban1"
              style="font-size: 26px"
              @click="fullscreen = false"
          />
          <i
              class="iconfont icon-zuidahua"
              style="font-size: 26px"
              @click="fullscreen = true"
          />
          <i
              class="el-icon-close"
              style="font-size: 26px"
              @click="realTimePosVisible = false"
          />
        </div>
      </div>
      <VehiclePosition :orderNumber="selectOrderNumber" :city="selectOrderCity"/>
    </el-dialog>

    <!-- 车辆轨迹-->
    <el-dialog
        v-if="orderTraceVisible"
        class="nopadding"
        :visible.sync="orderTraceVisible"
        :show-close="false"
        :style="'height:' + pageHeight * 0.9 + 'px'" width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>车辆轨迹</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderTraceVisible = false"
        />
      </div>
      <VehicleTrace
          :orderNumber="selectOrderNumber"
          :city="selectOrderCity"
          :startTime="selectOrderStartTime"
      />
    </el-dialog>

    <!-- 非运营订单新增 -->
    <el-dialog
        v-if="orderAddVisible"
        class="nopadding"
        width="60%"
        :visible.sync="orderAddVisible"
        :show-close="false"
    >
      <div slot="title" class="dialog_header">
        <span>非运营订单新增</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderAddVisible = false"
        />
      </div>
      <OrderAdd @closeWindow="orderAddVisible = false"/>
    </el-dialog>

    <!-- 非运营订单修改 -->
    <el-dialog
        v-if="orderEditVisible"
        class="nopadding"
        :visible.sync="orderEditVisible"
        :show-close="false"
        :style="'height:' + pageHeight * 0.9 + 'px'" width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>非运营订单修改</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderEditVisible = false"
        />
      </div>
      <OrderEdit
          :orderNumber="selectOrderNumber"
          :is-check="!editOrderInfoFlag"
          @closeWindow="orderEditVisible = false"
      />
    </el-dialog>

    <!-- 订单合并 -->
    <el-dialog
        v-if="orderMergeVisible"
        :visible.sync="orderMergeVisible"
        :show-close="false" class="nopadding"
        :style="'height:' + pageHeight * 0.9 + 'px'" width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>订单合并</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="orderMergeVisible = false"
        />
      </div>
      <OrderMerge/>
    </el-dialog>

    <!--  报警历史列表 -->
    <el-dialog
        v-if="alarmListVisible"
        :visible.sync="alarmListVisible"
        :show-close="false"
        width="60%"
    >
      <div slot="title" class="dialog_header">
        <span>非运营订单备注列表</span>
        <i
            class="el-icon-close"
            style="font-size: 26px"
            @click="alarmListVisible = false"
        />
      </div>
      <OrderRemarkListInfo :orderNum.sync="selectOrderNumber"/>
    </el-dialog>
  </div>
</template>
<script>
import orderApi from "@/api/orderpre";
import CarInfo from "@/components/CarInfo";
import OrderInfo from "@/components/OrderInfo";
import OrderAdd from "@/components/NoOperateOrderAdd";
import OrderEdit from "@/components/NoOperateOrderEdit";
import OrderMerge from "@/components/OrderMerge";
import fenceApi from "@/api/fence";
import VehiclePosition from "@/components/VehiclePosition";
import VehicleTrace from "@/components/VehicleTrace";
import monitor from "@/api/monitor";
import OrderRemarkListInfo from "@/components/NoOperateOrderRemarkList";
import {Message} from "element-ui";
import {formatNopreOrderType, isNoOperateOrderStatus} from "@/api/orderview";
import {formatTimeToStr} from "@/api/date";
import {export2File} from "@/api/exp2file";
import {ASSET_MANAGE, checkPermission} from "@/permission";

export default {
  data() {
    return {
      contentHeight: 0,
      searchOption: {
        orderNumber: "",
        vehicleSn: "",
        userName: "",
        warnType: "",
        city: "",
        pageSize: 10,
        cur_page: 1,
        totalCount: 0,
        carNumber: "",
        orderType: "",
        status: "",
        orderCity: "",
        sortby: "",
        order: "",
        carName: "",
        carVin: "",
      },
      endTime: [],
      startTime: [],
      tableData: [],
      cities: [],
      orderInfoVisible: false,
      realTimePosVisible: false,
      orderTraceVisible: false,
      orderAddVisible: false,
      orderEditVisible: false,
      orderMergeVisible: false,
      pageHeight: 0,
      carInfoVisible: false,
      alarmListVisible: false,
      selectOrderNumber: "",
      selectOrderCity: "",
      inputFocus: "",
      carInfo: {},
      autoOrderMergeTime: 60, //订单合并检测时间秒
      labelTxt: "订单合并(0条)",
      proptype: "", //存放column.prop的字符串值
      mergetimer: null,
      UN_OPERATE_LIST_P: ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST_P,
      loading: false,
      exportLoading: false,
    };
  },
  components: {
    CarInfo,
    OrderInfo,
    VehiclePosition,
    VehicleTrace,
    OrderEdit,
    OrderAdd,
    OrderMerge,
    OrderRemarkListInfo,
  },
  computed: {
    showCarInfoFlag() {
      return checkPermission([this.UN_OPERATE_LIST_P.CAR]);
    },
    showOrderInfoFlag() {
      return checkPermission([this.UN_OPERATE_LIST_P.DETAIL, this.UN_OPERATE_LIST_P.EDIT]);
    },
    editOrderInfoFlag() {
      return checkPermission([this.UN_OPERATE_LIST_P.EDIT]);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs.page.offsetHeight;
      this.pageHeight = document.documentElement.clientHeight;
      fenceApi.findCitys().then((res) => {
        if (res.code == 0) {
          this.cities = res.result;
        }
      });
      this.loadData();
      this.loadOrderMergeData();
      //this.autoLoadOrderMerge(); //订单合并
    });
  },
  beforeDestroy() {
    clearInterval(this.mergetimer);
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout();
    });
  },
  methods: {
    loadData() {
      let param = {
        pageNum: this.searchOption.cur_page,
        pageSize: this.searchOption.pageSize,
        orderNumber: this.searchOption.orderNumber,
        carNumber: this.searchOption.vehicleSn,
        orderType: this.searchOption.orderType,
        orderUser: this.searchOption.userName,
        status: this.searchOption.orderStatus,
        orderCity: this.searchOption.city,
        sortby: this.searchOption.sortby,
        order: this.searchOption.order,
        carName: this.searchOption.carName,
        carVin: this.searchOption.carVin,
        insertTimeS:
            this.startTime && this.startTime.length > 1 ? this.startTime[0] : "", //
        insertTimeE:
            this.startTime && this.startTime.length > 1 ? this.startTime[1] : "", //
        endTimeS:
            this.endTime && this.endTime.length > 1 ? this.endTime[0] : "", //
        endTimeE:
            this.endTime && this.endTime.length > 1 ? this.endTime[1] : "",
      };
      //console.log(this.searchOption.cur_page);
      this.loading = true;
      orderApi.getListInMysql(param).then((res) => {
        if (res.code == 0) {
          this.tableData = res.result.list;
          this.searchOption.totalCount = res.result.total;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    ExcelEntity() {
      let param = {
        pageNum: this.searchOption.cur_page,
        pageSize: this.searchOption.pageSize,
        orderNumber: this.searchOption.orderNumber,
        carNumber: this.searchOption.vehicleSn,
        orderType: this.searchOption.orderType,
        orderUser: this.searchOption.userName,
        status: this.searchOption.orderStatus,
        orderCity: this.searchOption.city,
        sortby: this.searchOption.sortby,
        order: this.searchOption.order,
        carName: this.searchOption.carName,
        carVin: this.searchOption.carVin,
        insertTimeS:
            this.startTime && this.startTime.length > 1 ? this.startTime[0] : "", //
        insertTimeE:
            this.startTime && this.startTime.length > 1 ? this.startTime[1] : "", //
        endTimeS:
            this.endTime && this.endTime.length > 1 ? this.endTime[0] : "", //
        endTimeE:
            this.endTime && this.endTime.length > 1 ? this.endTime[1] : "", //
      };
      let myObj = {
        url:
            process.env.VUE_APP_BASE_API +
            "/nooperateorder/exportNoOperateOrderList",
        fileName: "非运营订单列表",
        data: param,
      };
      this.exportLoading = true;
      setTimeout(() => {
        this.exportLoading = false
      }, 15000);
      export2File(myObj);
    },
    tableSortChange(column) {
      this.searchOption.cur_page = 1; //排序后从第一页开始
      if (column.order === "descending") {
        this.searchOption.sortby = column.prop;
        this.searchOption.order = "desc";
      } else {
        this.searchOption.sortby = column.prop;
        this.searchOption.order = "asc";
      }
      this.loadData();
    },
    viewAlarm(id) {
      //非运营订单备注列表
      this.selectOrderNumber = id;
      this.alarmListVisible = true;
    },
    handleSearch() {
      this.searchOption.cur_page = 1;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.searchOption.cur_page = val;
      this.loadData();
    },
    orderAdd() {
      //非运营订单新增
      this.orderAddVisible = true;
    },
    orderEdit(v) {
      if (!this.showOrderInfoFlag) {
        return;
      }
      //非运营订单修改
      this.selectOrderNumber = v;
      this.orderEditVisible = true;
    },
    orderMerge() {
      //订单合并
      this.orderMergeVisible = true;
    },
    showCarInfo(obj) {
      if (!this.showCarInfoFlag) {
        return;
      }
      this.carInfoVisible = true;
      this.carInfo = obj;
    },
    selectOrder(sn) {
      this.selectOrerId = sn;
      this.orderInfoVisible = true;
    },
    autoLoadOrderMerge() {
      let self = this;
      self.mergetimer = setInterval(function () {
        self.loadOrderMergeData();
      }, self.autoOrderMergeTime * 1000);
    },
    updateRemark(row) {
      let param = {
        orderNumber: row.orderNumber,
        remark: row.remark,
      };
      if (row.remark.length > 100) {
        Message.error({
          message: "备注内容最多100个字符！",
        });
      }
      orderApi.editRemark(param).then((res) => {
        if (res.code == 0) {
          this.loadData();
        } else {
          Message.error({
            message: res.msg,
          });
        }
      });
      this.inputFocus = "";
    },
    loadOrderMergeData() {
      monitor.getOrderClashCount().then((res) => {
        if (res.code == 0) {
          this.labelTxt = "订单合并[" + res.result + "]条";
        }
      });
    },
    /**
     * 定位监控的设备
     */
    locationEquipment(v, c) {
      // this.$router.push({path:'/trace',params:{eno:'1111111'}})
      // this.$router.push({path:'/trace',params:{eno:'1111111'}})
      // this.selectOrderNumber = v
      // this.selectOrderCity = c
      // this.realTimePosVisible = true
      // this.fullscreen = true
      //弹出页面效果
      let params = {carNumber: v, city: c, equipNumber: ""};

      const {href} = this.$router.resolve({
        path: "/position",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");
    },
    /**
     * 订单轨迹
     */
    toTrace(obj) {
      // this.$router.push({
      //   path: "/property/trace",
      //   query: {
      //     carNumber: obj.carVo.carNumber,
      //     factory: "",
      //   },
      // });
      let params = {
        carNumber: obj.carVo.carNumber,
        pickTime: obj.realPickTime,
        factory: "",
      };
      const {href} = this.$router.resolve({
        path: "/trace",
        query: params,
      });
      // console.log(href)
      window.open(href, "_blank", "toolbar=yes, width=900, height=700");
    },
    // eslint-disable-next-line no-unused-vars
    formatTime(t, c, cellValue, index) {
      return formatTimeToStr(cellValue);
    },
    formatOrder(t, c, cellValue) {
      return isNoOperateOrderStatus(cellValue);
    },
    timeToStr(t) {
      if (t) {
        return formatTimeToStr(t);
      } else {
        return "--";
      }
    },
    indexMethod(index) {
      let curPage = this.searchOption.cur_page;
      let pageSize = this.searchOption.pageSize;
      return index + 1 + (curPage - 1) * pageSize;
    },
    formatNopreOrderType(v) {
      return formatNopreOrderType(v);
    },
  },
};
</script>
<style scoped>
.data_wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.handle-input {
  width: 150px;
  display: inline-block;
}

.handle-select {
  width: 120px;
}

.searchBtn {
  height: 30px;
  background: #3b7bcf;
}

.verticalCol {
  display: flex;
  flex-direction: column;
}

.myDrawer {
  width: 60%;
}

.dialog_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  color: #fff;
}

.editIcon {
  color: #3b7bcf;
  cursor: pointer;
}
</style>
<style less>
.el-dialog__header {
  background: rgb(26, 36, 62);
  display: flex;
}

.nopadding .el-dialog__body {
  padding: 0px;
}
</style>
