import user from "@/store/modules/user";

/**
 * 数据监控权限
 * @type {{}}
 */
export const DATA_MONITORING = {
  // 运营报警列表
  OPERATE_ALARM: {
    // 新增
    ADD: 'data.monitoring.operate.add',
    // 导出
    EXPORT: 'data.monitoring.operate.export',
    // 合并订单
    MERGE: 'data.monitoring.operate.merge',
    // 位置
    LOCATION: 'data.monitoring.operate.location',
    // 提醒
    REMIND: 'data.monitoring.operate.remind',
    // 关注
    FOLLOW: 'data.monitoring.operate.follow',
    // 轨迹
    TRACE: 'data.monitoring.operate.trace',
    // 订单信息
    DETAIL: 'data.monitoring.operate.detail',
    EDIT: 'data.monitoring.operate.edit',
    // 车辆信息
    CAR: 'data.monitoring.operate.car',
    // 订单备注
    ORDER_REMARK: 'data.monitoring.operate.order.remark',
    ORDER_REMARK_LOG: 'data.monitoring.operate.order.remark.log',
    ORDER_REMARK_EXPORT: 'data.monitoring.operate.order.remark.export',
    // 备注
    REMARK: 'data.monitoring.operate.remark',
    // 查看
    REMARK_DETAIL: 'data.monitoring.operate.remark.detail',
    // 消除
    REMOVE: 'data.monitoring.operate.remove',
    // 批消
    REMOVE_BATCH: 'data.monitoring.operate.remove.batch',
    // 更新提醒优先级
    REMIND_UPDATE: 'data.monitoring.operate.remind.update',
    // 更新故障状态
    TROUBLE_STATUS: 'data.monitoring.operate.trouble.status'

  },
  // 非运营报警列表
  UN_OPERATE_ALARM: {
    // 新增
    ADD: 'data.monitoring.un.operate.add',
    // 导出
    EXPORT: 'data.monitoring.un.operate.export',
    // 合并订单
    MERGE: 'data.monitoring.un.operate.merge',
    // 位置
    LOCATION: 'data.monitoring.un.operate.location',
    // 提醒
    REMIND: 'data.monitoring.un.operate.remind',
    // 关注
    FOLLOW: 'data.monitoring.un.operate.follow',
    // 轨迹
    TRACE: 'data.monitoring.un.operate.trace',
    // 订单信息
    DETAIL: 'data.monitoring.un.operate.detail',
    EDIT: 'data.monitoring.un.operate.edit',
    // 车辆信息
    CAR: 'data.monitoring.un.operate.car',
    // 备注
    REMARK: 'data.monitoring.un.operate.remark',
    // 查看
    REMARK_DETAIL: 'data.monitoring.un.operate.remark.detail',
    // 消除
    REMOVE: 'data.monitoring.un.operate.remove',
  },
  // 在库车辆报警列表
  STORAGE_CAR_ALARM: {
    // 导出
    EXPORT: 'data.monitoring.storage.export',
    // 车辆信息
    CAR: 'data.monitoring.storage.car',
    // 位置
    LOCATION: 'data.monitoring.storage.location',
    // 提醒
    REMIND: 'data.monitoring.storage.remind',
    // 轨迹
    TRACE: 'data.monitoring.storage.trace',
    // 备注
    REMARK: 'data.monitoring.storage.remark',
    // 查看
    REMARK_DETAIL: 'data.monitoring.storage.remark.detail',
    // 消除
    REMOVE: 'data.monitoring.storage.remove',
  },
  // 报警历史列表
  ALARM_HISTORY: {
    // 运营订单
    OPERATE_LIST: 'data.monitoring.alarm.operate',
    OPERATE_LIST_P: {
      // 导出订单
      EXPORT: 'data.monitoring.alarm.operate.export',
      // 位置
      LOCATION: 'data.monitoring.alarm.operate.location',
      // 轨迹
      TRACE: 'data.monitoring.alarm.operate.trace',
      // 订单详情
      DETAIL: 'data.monitoring.alarm.operate.detail',
      // 编辑订单
      EDIT: 'data.monitoring.alarm.operate.edit',
      // 车辆信息
      CAR: 'data.monitoring.alarm.operate.car',
      // 备注-查看
      REMARK: 'data.monitoring.alarm.operate.remark'
    },
    // 非运营订单
    UN_OPERATE_LIST: 'data.monitoring.alarm.un.operate',
    UN_OPERATE_LIST_P: {
      // 导出订单
      EXPORT: 'data.monitoring.alarm.un.operate.export',
      // 位置
      LOCATION: 'data.monitoring.alarm.un.operate.location',
      // 轨迹
      TRACE: 'data.monitoring.alarm.un.operate.trace',
      // 订单详情
      DETAIL: 'data.monitoring.alarm.un.operate.detail',
      // 编辑订单
      EDIT: 'data.monitoring.alarm.un.operate.edit',
      // 车辆信息
      CAR: 'data.monitoring.alarm.un.operate.car',
      // 备注-查看
      REMARK: 'data.monitoring.alarm.un.operate.remark'
    },
    // 在库车辆查询
    STORAGE_CAR_LIST: 'data.monitoring.alarm.storage',
    STORAGE_CAR_LIST_P: {
      // 导出订单
      EXPORT: 'data.monitoring.alarm.storage.export',
      // 位置
      LOCATION: 'data.monitoring.alarm.storage.location',
      // 轨迹
      TRACE: 'data.monitoring.alarm.storage.trace',
      // 车辆信息
      CAR: 'data.monitoring.alarm.storage.car',
      // 备注-查看
      REMARK: 'data.monitoring.alarm.storage.remark'
    }
  },
  // 位置跟踪  暂时不做权限
  LOCATION: {
    // 导出轨迹
    EXPORT: 'data.monitoring.location.export',
    // 开启测距
    RANGING: 'data.monitoring.location.ranging',
    // 播放轨迹
    PLAY: 'data.monitoring.location.play'
  }
};

/**
 * 资产管理系统设置
 * @type {{}}
 */
export const ASSET_MANAGE = {
  // 订单查询
  ORDER_QUERY: {
    // 运营订单
    OPERATE_LIST: 'asset.manage.order.operate',
    OPERATE_LIST_P: {
      // 新增
      ADD: 'asset.manage.order.operate.add',
      // 订单合并
      MERGE: 'asset.manage.order.operate.merge',
      // 导出订单
      EXPORT: 'asset.manage.order.operate.export',
      // 位置
      LOCATION: 'asset.manage.order.operate.location',
      // 轨迹
      TRACE: 'asset.manage.order.operate.trace',
      // 订单详情
      DETAIL: 'asset.manage.order.operate.detail',
      // 编辑订单
      EDIT: 'asset.manage.order.operate.edit',
      // 车辆信息
      CAR: 'asset.manage.order.operate.car',
      // 备注
      REMARK_EDIT: 'asset.manage.order.operate.remark.edit',
      // 备注-查看
      REMARK_DETAIL: 'asset.manage.order.operate.remark.detail',
      // 备注-日志
      REMARK_LOG: 'asset.manage.order.operate.remark.log'
    },
    // 非运营订单
    UN_OPERATE_LIST: 'asset.manage.order.un.operate',
    UN_OPERATE_LIST_P: {
      // 新增
      ADD: 'asset.manage.order.un.operate.add',
      // 导出订单
      EXPORT: 'asset.manage.order.un.operate.export',
      // 位置
      LOCATION: 'asset.manage.order.un.operate.location',
      // 轨迹
      TRACE: 'asset.manage.order.un.operate.trace',
      // 订单详情
      DETAIL: 'asset.manage.order.un.operate.detail',
      // 编辑订单
      EDIT: 'asset.manage.order.un.operate.edit',
      // 车辆信息
      CAR: 'asset.manage.order.un.operate.car',
      // 备注
      REMARK_EDIT: 'asset.manage.order.un.operate.remark.edit',
      // 备注-查看
      REMARK_DETAIL: 'asset.manage.order.un.operate.remark.detail'
    },
    // 在库车辆查询
    STORAGE_CAR_LIST: 'asset.manage.order.storage.car',
  },
  // 车辆查询
  CAR_QUERY: {
    // 导出
    EXPORT: 'asset.manage.car.export',
    // 修改车辆状态
    CAR_STATUS: 'asset.manage.car.car.status',
    // 修改库存状态
    STORAGE_STATUS: 'asset.manage.car.storage.status',
    // 修改设备状态
    DEVICE_STATUS: 'asset.manage.car.device.status',
  },
  // 设备查询
  DEVICE_QUERY: {
    // 导出
    EXPORT: 'asset.manage.device.export',
    // 导入
    IMPORT: 'asset.manage.device.import',
    // 下载模版
    TEMPLATE: 'asset.manage.device.template',
    // 批量下线
    BATCH_DOWN: 'asset.manage.device.batch.down',
    // 批量上线
    BATCH_UP: 'asset.manage.device.batch.up',
    // 修改设备状态
    DEVICE_STATUS: 'asset.manage.device.device.status',
    // 修改故障状态
    TROUBLE_STATUS: 'asset.manage.device.trouble.status',
    // 修改库存状态
    STORAGE_STATUS: 'asset.manage.device.storage.status',
    // 车辆信息
    CAR: 'asset.manage.device.car',
    // 位置
    LOCATION: 'asset.manage.device.location',
    // 轨迹
    TRACE: 'asset.manage.device.trace',
    // 删除
    DELETE: 'asset.manage.device.delete'
  },
  // 监控中设备查询
  MONITORING_DEVICE: {
    // 车辆信息
    CAR: 'asset.manage.monitoring.device.car'
  },
  // 监控中订单
  MONITORING_ORDER: {
    // 订单信息
    ORDER: 'asset.manage.monitoring.order.order',
    // 车辆信息
    CAR: 'asset.manage.monitoring.order.car'
  }
}

/**
 * 报表统计权限
 * @type {{}}
 */
export const REPORT_FORMS = {
  // 高危报警
  HIGH_RISK_ALARM: {
    // 导出
    EXPORT: 'report.forms.high.risk.alarm.export',
    // 位置
    LOCATION: 'report.forms.high.risk.alarm.location',
    // 轨迹
    TRACE: 'report.forms.high.risk.alarm.trace',
    // 订单信息
    ORDER: 'report.forms.high.risk.alarm.order',
    // 车辆信息
    CAR: 'report.forms.high.risk.alarm.car',
    // 修改故障状态
    TROUBLE_STATUS: 'report.forms.high.risk.alarm.trouble.status',
  },
  // 超时未行使统计表
  TIME_OUT_LIST: {
    // 导出
    EXPORT: 'report.forms.time.out.list.export',
    // 位置
    LOCATION: 'report.forms.time.out.list.location',
    // 轨迹
    TRACE: 'report.forms.time.out.list.trace',
    // 订单信息
    ORDER: 'report.forms.time.out.list.order',
    // 车辆信息
    CAR: 'report.forms.time.out.list.car',
    // 修改故障状态
    TROUBLE_STATUS: 'report.forms.high.risk.alarm.trouble.status',
  },
  // 设备故障列表
  DEVICE_ERROR_LIST: {
    // 导出
    EXPORT: 'report.forms.device.error.list.export',
    // 订单信息
    ORDER: 'report.forms.device.error.list.order',
    // 车辆信息
    CAR: 'report.forms.device.error.list.car',
  },
  // 城市车辆汇总
  CAR_SUMMARY: {
    EXPORT: 'report.forms.car.summary.export'
  }
}

/**
 * 系统设置权限
 * @type {{}}
 */
export const SYSTEM_SETTING = {
  // 监控设置权限
  MONITORING_SETTING: {
    OPERATE_SETTING: {
      EDIT: 'system.monitor.monitoring.operate.edit'
    },
    UN_OPERATE_SETTING: {
      EDIT: 'system.monitor.monitoring.un.operate.edit'
    },
    STORAGE_SETTING: {
      EDIT: 'system.monitor.monitoring.storage.edit'
    },
  },
  // 围栏设置权限
  FENCE_SETTING: {
    ADD: 'system.monitor.fence.add',
    DETAIL: 'system.monitor.fence.detail',
    EDIT: 'system.monitor.fence.edit',
    DELETE: 'system.monitor.fence.delete',
    DEVICE_LIST: 'system.monitor.fence.device.list',
    BIND: 'system.monitor.fence.bind',
    UN_BIND: 'system.monitor.fence.un.bind',
  }
}


// eslint-disable-next-line no-unused-vars
const mock_permissions = [
  DATA_MONITORING.OPERATE_ALARM.ADD,
  DATA_MONITORING.OPERATE_ALARM.EXPORT,
  DATA_MONITORING.OPERATE_ALARM.MERGE,
  DATA_MONITORING.OPERATE_ALARM.LOCATION,
  DATA_MONITORING.OPERATE_ALARM.REMIND,
  DATA_MONITORING.OPERATE_ALARM.FOLLOW,
  DATA_MONITORING.OPERATE_ALARM.TRACE,
  DATA_MONITORING.OPERATE_ALARM.DETAIL,
  DATA_MONITORING.OPERATE_ALARM.EDIT,
  DATA_MONITORING.OPERATE_ALARM.CAR,
  DATA_MONITORING.OPERATE_ALARM.ORDER_REMARK,
  DATA_MONITORING.OPERATE_ALARM.ORDER_REMARK_LOG,
  DATA_MONITORING.OPERATE_ALARM.ORDER_REMARK_EXPORT,
  DATA_MONITORING.OPERATE_ALARM.REMARK,
  DATA_MONITORING.OPERATE_ALARM.REMARK_DETAIL,
  DATA_MONITORING.OPERATE_ALARM.REMOVE,
  DATA_MONITORING.OPERATE_ALARM.REMOVE_BATCH,
  DATA_MONITORING.OPERATE_ALARM.REMIND_UPDATE,
  DATA_MONITORING.OPERATE_ALARM.TROUBLE_STATUS,

  DATA_MONITORING.UN_OPERATE_ALARM.ADD,
  DATA_MONITORING.UN_OPERATE_ALARM.EXPORT,
  DATA_MONITORING.UN_OPERATE_ALARM.MERGE,
  DATA_MONITORING.UN_OPERATE_ALARM.LOCATION,
  DATA_MONITORING.UN_OPERATE_ALARM.REMIND,
  DATA_MONITORING.UN_OPERATE_ALARM.FOLLOW,
  DATA_MONITORING.UN_OPERATE_ALARM.TRACE,
  DATA_MONITORING.UN_OPERATE_ALARM.DETAIL,
  DATA_MONITORING.UN_OPERATE_ALARM.EDIT,
  DATA_MONITORING.UN_OPERATE_ALARM.CAR,
  DATA_MONITORING.UN_OPERATE_ALARM.REMARK,
  DATA_MONITORING.UN_OPERATE_ALARM.REMARK_DETAIL,
  DATA_MONITORING.UN_OPERATE_ALARM.REMOVE,

  DATA_MONITORING.STORAGE_CAR_ALARM.EXPORT,
  DATA_MONITORING.STORAGE_CAR_ALARM.CAR,
  DATA_MONITORING.STORAGE_CAR_ALARM.LOCATION,
  DATA_MONITORING.STORAGE_CAR_ALARM.REMIND,
  DATA_MONITORING.STORAGE_CAR_ALARM.TRACE,
  DATA_MONITORING.STORAGE_CAR_ALARM.REMARK,
  DATA_MONITORING.STORAGE_CAR_ALARM.REMARK_DETAIL,
  DATA_MONITORING.STORAGE_CAR_ALARM.REMOVE,

  DATA_MONITORING.ALARM_HISTORY.OPERATE_LIST,
  DATA_MONITORING.ALARM_HISTORY.OPERATE_LIST_P.EXPORT,
  DATA_MONITORING.ALARM_HISTORY.OPERATE_LIST_P.LOCATION,
  DATA_MONITORING.ALARM_HISTORY.OPERATE_LIST_P.TRACE,
  DATA_MONITORING.ALARM_HISTORY.OPERATE_LIST_P.DETAIL,
  DATA_MONITORING.ALARM_HISTORY.OPERATE_LIST_P.EDIT,
  DATA_MONITORING.ALARM_HISTORY.OPERATE_LIST_P.CAR,
  DATA_MONITORING.ALARM_HISTORY.OPERATE_LIST_P.REMARK,

  DATA_MONITORING.ALARM_HISTORY.UN_OPERATE_LIST,
  DATA_MONITORING.ALARM_HISTORY.UN_OPERATE_LIST_P.EXPORT,
  DATA_MONITORING.ALARM_HISTORY.UN_OPERATE_LIST_P.LOCATION,
  DATA_MONITORING.ALARM_HISTORY.UN_OPERATE_LIST_P.TRACE,
  DATA_MONITORING.ALARM_HISTORY.UN_OPERATE_LIST_P.DETAIL,
  DATA_MONITORING.ALARM_HISTORY.UN_OPERATE_LIST_P.EDIT,
  DATA_MONITORING.ALARM_HISTORY.UN_OPERATE_LIST_P.CAR,
  DATA_MONITORING.ALARM_HISTORY.UN_OPERATE_LIST_P.REMARK,

  DATA_MONITORING.ALARM_HISTORY.STORAGE_CAR_LIST,
  DATA_MONITORING.ALARM_HISTORY.STORAGE_CAR_LIST_P.EXPORT,
  DATA_MONITORING.ALARM_HISTORY.STORAGE_CAR_LIST_P.LOCATION,
  DATA_MONITORING.ALARM_HISTORY.STORAGE_CAR_LIST_P.TRACE,
  DATA_MONITORING.ALARM_HISTORY.STORAGE_CAR_LIST_P.CAR,
  DATA_MONITORING.ALARM_HISTORY.STORAGE_CAR_LIST_P.REMARK,

  ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST,
  ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST_P.ADD,
  ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST_P.EXPORT,
  ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST_P.LOCATION,
  ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST_P.TRACE,
  ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST_P.DETAIL,
  ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST_P.EDIT,
  ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST_P.CAR,
  ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST_P.REMARK_EDIT,
  ASSET_MANAGE.ORDER_QUERY.OPERATE_LIST_P.REMARK_DETAIL,
  ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST,
  ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST_P.ADD,
  ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST_P.EXPORT,
  ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST_P.LOCATION,
  ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST_P.TRACE,
  ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST_P.DETAIL,
  ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST_P.EDIT,
  ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST_P.CAR,
  ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST_P.REMARK_EDIT,
  ASSET_MANAGE.ORDER_QUERY.UN_OPERATE_LIST_P.REMARK_DETAIL,
  ASSET_MANAGE.ORDER_QUERY.STORAGE_CAR_LIST,
  ASSET_MANAGE.CAR_QUERY.EXPORT,
  ASSET_MANAGE.CAR_QUERY.CAR_STATUS,
  ASSET_MANAGE.CAR_QUERY.STORAGE_STATUS,
  ASSET_MANAGE.CAR_QUERY.DEVICE_STATUS,

  ASSET_MANAGE.DEVICE_QUERY.EXPORT,
  ASSET_MANAGE.DEVICE_QUERY.IMPORT,
  ASSET_MANAGE.DEVICE_QUERY.TEMPLATE,
  ASSET_MANAGE.DEVICE_QUERY.BATCH_DOWN,
  ASSET_MANAGE.DEVICE_QUERY.BATCH_UP,
  ASSET_MANAGE.DEVICE_QUERY.DEVICE_STATUS,
  ASSET_MANAGE.DEVICE_QUERY.TROUBLE_STATUS,
  ASSET_MANAGE.DEVICE_QUERY.STORAGE_STATUS,
  ASSET_MANAGE.DEVICE_QUERY.CAR,
  ASSET_MANAGE.DEVICE_QUERY.LOCATION,
  ASSET_MANAGE.DEVICE_QUERY.TRACE,
  ASSET_MANAGE.DEVICE_QUERY.DELETE,

  ASSET_MANAGE.MONITORING_DEVICE.CAR,

  ASSET_MANAGE.MONITORING_ORDER.CAR,
  ASSET_MANAGE.MONITORING_ORDER.ORDER,

  REPORT_FORMS.HIGH_RISK_ALARM.EXPORT,
  REPORT_FORMS.HIGH_RISK_ALARM.LOCATION,
  REPORT_FORMS.HIGH_RISK_ALARM.TRACE,
  REPORT_FORMS.HIGH_RISK_ALARM.ORDER,
  REPORT_FORMS.HIGH_RISK_ALARM.CAR,
  REPORT_FORMS.HIGH_RISK_ALARM.TROUBLE_STATUS,
  REPORT_FORMS.TIME_OUT_LIST.EXPORT,
  REPORT_FORMS.TIME_OUT_LIST.LOCATION,
  REPORT_FORMS.TIME_OUT_LIST.TRACE,
  REPORT_FORMS.TIME_OUT_LIST.ORDER,
  REPORT_FORMS.TIME_OUT_LIST.CAR,
  REPORT_FORMS.TIME_OUT_LIST.TROUBLE_STATUS,
  REPORT_FORMS.DEVICE_ERROR_LIST.EXPORT,
  REPORT_FORMS.DEVICE_ERROR_LIST.ORDER,
  REPORT_FORMS.DEVICE_ERROR_LIST.CAR,
  REPORT_FORMS.CAR_SUMMARY.EXPORT,

  SYSTEM_SETTING.FENCE_SETTING.ADD,
  SYSTEM_SETTING.FENCE_SETTING.DETAIL,
  SYSTEM_SETTING.FENCE_SETTING.EDIT,
  SYSTEM_SETTING.FENCE_SETTING.DELETE,
  SYSTEM_SETTING.FENCE_SETTING.DEVICE_LIST,
  SYSTEM_SETTING.FENCE_SETTING.BIND,
  SYSTEM_SETTING.FENCE_SETTING.UN_BIND,
  SYSTEM_SETTING.MONITORING_SETTING.OPERATE_SETTING.EDIT,
  SYSTEM_SETTING.MONITORING_SETTING.UN_OPERATE_SETTING.EDIT,
  SYSTEM_SETTING.MONITORING_SETTING.STORAGE_SETTING.EDIT,
];

export function checkPermission(checkValues) {
  const permission = user.state.permissions;
  // console.log('checkPermission', checkValues, permission);
  if (checkValues
      && checkValues instanceof Array
      && checkValues.length > 0
  ) {
    const permissionFlag = checkValues;
    return permission.some(item => {
      return permissionFlag.includes(item)
    });
  } else {
    return false;
  }
}
